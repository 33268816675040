import get from 'lodash/get';

export const METHOD_ADD_TO_CART = 'addtocart';

export const METHOD_CONFIGURATION_SAVED = 'configurationsaved';

export const METHOD_CONFIGURATION_SAVED_REMOTELY =
  'configurationsaved_remotely';

export const LISTENER_SWITCH_OPTIONS = 'switch_options';

export const LISTENER_SAVE_CONFIGURATION = 'save_configuration';

/**
 * Helper interface for bi-directional postMessage API
 */
export default function createMessageInterface() {
  const messageHandlers = [];

  window.addEventListener(
    'message',
    (event) => {
      const typeToFind = get(event, 'data.type');
      messageHandlers
        .filter(({ type }) => type === typeToFind)
        .forEach(({ handler }) => handler(event));
    },
    false
  );

  return {
    /**
     * @param {string} type
     * @param {function(event)} handler
     */
    addListener(type, handler) {
      messageHandlers.push({ type, handler });
    },
    /**
     * @param {object} messageObj
     * @param {object} [options]
     */
    dispatch(messageObj, options = {}) {
      const { targetWindow = window.parent, targetOrigin = '*' } = options;
      targetWindow &&
        targetWindow.postMessage(
          messageObj,
          targetOrigin || targetWindow.origin
        );
    }
  };
}
