import {
  Button,
  DialogContent,
  DialogActions,
  Box,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { t } from 'react-i18nify';
import React, { ReactElement, FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IHint } from '../Interface/Interface';
import { useAppSelector } from '../hooks';
import { red } from '../Constants/Colors';
import { AbsoluteDialog } from './ContainerPositionProvider';

interface IValidationErrorProps {
  open: boolean;
  handleClose?: () => void;
  errorMessage?: string;
  title?: string;
}
const useStyles = makeStyles()((theme) => {
  return {
    MuiDialogBorder: {
      borderTop: '7px solid ' + theme.palette.primary.main
    },
    dialogTitleRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px'
    },
    dialogContentRoot: {
      padding: '0 24px 0 12px'
    },
    okButton: {
      padding: '6px 15px !important',
      width: '20%'
    },
    hints: {
      color: `${red} !important`,
      fontWeight: 'bold !important'
    },
    closeIcon: {
      position: 'absolute',
      right: 28,
      top: 28
    }
  };
});

const ValidationErrorDialog: FC<IValidationErrorProps> = ({
  open,
  handleClose,
  errorMessage,
  title
}): ReactElement => {
  const { classes } = useStyles();
  const hints: IHint[] | undefined = useAppSelector(
    (state) => state.configuration.hints
  );

  return (
    <>
      <AbsoluteDialog
        classes={{
          paper: classes.MuiDialogBorder
        }}
        open={open}
        onClose={handleClose}>
        <Box className={classes.dialogTitleRoot}>
          <CloseIcon onClick={handleClose} />
        </Box>

        <DialogContent className={classes.dialogContentRoot}>
          <React.Fragment>
            <Typography variant="h4" sx={{ p: 1 }}>
              {t(title || 'ValidationErrorCheck')}
            </Typography>
            <Box sx={{ p: 1 }}>
              {errorMessage && (
                <Typography variant="h4" className={classes.hints}>
                  {t(errorMessage)}
                </Typography>
              )}
              {hints && (
                <Typography variant="h3" className={classes.hints}>
                  {t('verify')}
                </Typography>
              )}
              {hints &&
                hints.map((hint: IHint, i: number) => (
                  <Typography variant="h4" className={classes.hints} key={i}>
                    {t(hint.message)}
                  </Typography>
                ))}
            </Box>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            size="medium"
            className={classes.okButton}>
            {t('okText')}
          </Button>
        </DialogActions>
      </AbsoluteDialog>
    </>
  );
};

export default ValidationErrorDialog;
