import React, { ReactElement, FC } from 'react';
import {
  IAttribute,
  IComponent,
  IConfiguration,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import { useAppSelector } from '../hooks';
import { findFirstSelectedOptionByComponentIdentifier } from '../Utils/FindInConfiguration';
import {
  ATTRIBUTE_KEY_THERMAL_DESIGN_POWER,
  COMPONENT_CPU_COOLER_IDENTIFIER,
  COMPONENT_CPU_IDENTIFIER
} from '../Constants/Constants';
import DefaultComponent from './DefaultComponent';
import { getOptionLabel } from '../Utils/getOptionLabel';

interface IComponentProps {
  component: IComponent;
}

const CPUCoolerComponent: FC<IComponentProps> = ({
  component
}): ReactElement => {
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const selectedCPU: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_CPU_IDENTIFIER,
      selectedOptions,
      configuration
    );
  let cpuTdp = 0;
  if (selectedCPU) {
    const cpuTdpAttribute: IAttribute | undefined = selectedCPU.attributes.find(
      (attribute) => ATTRIBUTE_KEY_THERMAL_DESIGN_POWER === attribute.identifier
    );
    if (cpuTdpAttribute && cpuTdpAttribute.values[0]) {
      cpuTdp =
        cpuTdpAttribute.values[0].translations &&
        cpuTdpAttribute.values[0].translations.length > 0
          ? parseInt(cpuTdpAttribute.values[0].translated_value)
          : parseInt(cpuTdpAttribute.values[0].value);
    }
  }

  const isOptionDisabled = (option: IOption) => {
    let coolerTdp = 0;
    if (selectedCPU) {
      const coolerTdpAttribute: IAttribute | undefined = option.attributes.find(
        (attribute) =>
          ATTRIBUTE_KEY_THERMAL_DESIGN_POWER === attribute.identifier
      );
      if (coolerTdpAttribute && coolerTdpAttribute.values[0]) {
        coolerTdp =
          coolerTdpAttribute.values[0].translations &&
          coolerTdpAttribute.values[0].translations.length > 0
            ? parseInt(coolerTdpAttribute.values[0].translated_value)
            : parseInt(coolerTdpAttribute.values[0].value);
      }
    }
    return coolerTdp < cpuTdp;
  };

  const optionLabel = (option: IOption) => {
    return getOptionLabel(
      option,
      COMPONENT_CPU_COOLER_IDENTIFIER,
      selectedOptions
    );
  };

  return (
    <DefaultComponent
      component={component}
      checkOptionDisabled={isOptionDisabled}
      disableAmountSelection={true}
      optionLabel={optionLabel}
    />
  );
};

export default CPUCoolerComponent;
