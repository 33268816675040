import {
  IAttribute,
  IComponent,
  IConfiguration,
  IOption,
  ISelectedOption,
  ISelectedOptions
} from '../Interface/Interface';
import { first } from 'lodash';

export const findOptionByIdentifier = (
  optionIdentifier: string,
  configuration: IConfiguration,
  componentIdentifier: string | undefined
): IOption | undefined => {
  for (const component of configuration?.components) {
    if (componentIdentifier && component.identifier !== componentIdentifier) {
      continue;
    }
    for (const option of component.options) {
      if (optionIdentifier === option.identifier) {
        return option;
      }
    }
  }
  return;
};

export const findFirstSelectedOptionByComponentIdentifier = (
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined,
  configuration: IConfiguration | undefined
): IOption | undefined => {
  if (
    configuration &&
    selectedOptions &&
    selectedOptions[componentIdentifier]
  ) {
    return findOptionByIdentifier(
      selectedOptions[componentIdentifier][0].identifier,
      configuration,
      componentIdentifier
    );
  }
  return;
};

export const findSelectedOptionsByComponentIdentifier = (
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined,
  configuration: IConfiguration | undefined
): IOption[] => {
  const foundOptions: IOption[] = [];
  if (
    configuration &&
    selectedOptions &&
    selectedOptions[componentIdentifier]
  ) {
    for (const selectedOption of selectedOptions[componentIdentifier]) {
      const foundOption = findOptionByIdentifier(
        selectedOption.identifier,
        configuration,
        componentIdentifier
      );
      if (foundOption) {
        foundOptions.push(foundOption);
      }
    }
  }
  return foundOptions;
};

export const isOptionSelected = (
  option: IOption,
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined
): boolean => {
  let isSelected = false;
  if (selectedOptions && selectedOptions[componentIdentifier]) {
    const selectedOption = selectedOptions[componentIdentifier].find(
      (selectedOption) => selectedOption.identifier === option.identifier
    );
    if (selectedOption) {
      isSelected = true;
    }
  }
  return isSelected;
};

export const findSelectedOptionAmount = (
  option: IOption | undefined,
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined
): number => {
  let amount = 0;
  if (option && selectedOptions && selectedOptions[componentIdentifier]) {
    const selectedOption = selectedOptions[componentIdentifier].find(
      (selectedOption) => selectedOption.identifier === option.identifier
    );
    if (selectedOption) {
      amount = selectedOption.amount;
    }
  }
  return amount;
};

export const findUsernote = (
  option: IOption,
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined
): string => {
  let userNote = '';
  if (selectedOptions && selectedOptions[componentIdentifier]) {
    const selectedOption = selectedOptions[componentIdentifier].find(
      (selectedOption) => selectedOption.identifier === option.identifier
    );
    if (selectedOption) {
      userNote = selectedOption.userNote;
    }
  }
  return userNote;
};

export const findComponentByIdentifier = (
  componentIdentifier: string,
  configuration: IConfiguration
): IComponent | undefined => {
  for (const component of configuration?.components) {
    if (componentIdentifier === component.identifier) {
      return component;
    }
  }
  return;
};

export const getNumberOfSelectedOptions = (
  component: IComponent,
  selectedOptions: ISelectedOptions
): number => {
  let numSelected = 0;
  component.options.forEach((option) => {
    numSelected += findSelectedOptionAmount(
      option,
      component.identifier,
      selectedOptions
    );
  });
  return numSelected;
};

// eslint-disable-next-line no-unused-vars
export const getNumberOfSelectedDrives = (
  options: IOption[],
  selectedDiskOptions: ISelectedOption[],
  diskTypeAttribute: string,
  diskTypeAttributeValue: string
): number => {
  const fullSelectedOptions = selectedDiskOptions
    .map((selectedOption) =>
      options.find((option) => option.identifier === selectedOption.identifier)
    )
    .filter(Boolean)
    // @ts-ignore
    .filter((option) =>
      option?.attributes.find(
        (attribute) =>
          diskTypeAttribute === attribute.identifier &&
          attribute.values.length > 0 &&
          attribute.values[0].value === diskTypeAttributeValue
      )
    );

  // @ts-ignore
  const selectedOptionsAmounts = fullSelectedOptions.map((option) =>
    selectedDiskOptions.find(
      (selectedOption) => selectedOption.identifier == option?.identifier
    )
  );
  const selectedOptionsAmountsArray = selectedOptionsAmounts.map(
    (selectedOptionAmount) => selectedOptionAmount?.amount
  );

  // @ts-ignore
  return Math.max(...selectedOptionsAmountsArray);
};

export const getAttribute = (
  item: any,
  attributeIdentifier: string
): IAttribute =>
  item.attributes.find(
    (attribute: IAttribute) => attributeIdentifier === attribute.identifier
  );

export const getAttributeValue = <T extends string | number | null>(
  item: any,
  attributeIdentifier: string,
  defaultValue?: T,
  returnTranslated = false
) => {
  let value;

  if (defaultValue !== undefined) {
    value = defaultValue;
  }

  if (item) {
    const attrib = getAttribute(item, attributeIdentifier);
    if (attrib?.values?.length) {
      // @ts-ignore
      value = first(attrib.values)[
        returnTranslated ? 'translated_value' : 'value'
      ];
    }
  }

  return value;
};

export const getAllAttributeValues = (
  item: any,
  attributeIdentifier: string
) => {
  if (!item) {
    return [];
  }
  const attribute = getAttribute(item, attributeIdentifier);
  if (attribute?.values?.length) {
    return attribute.values.map((attributeValue) => attributeValue.value);
  }

  return [];
};
