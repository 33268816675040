import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { ReactElement, FC } from 'react';
import { t } from 'react-i18nify';
import { useAppSelector } from '../hooks';
import {
  IAttribute,
  IConfiguration,
  IGroupedComponents,
  IHint,
  ISelectedOptions
} from '../Interface/Interface';
import ConfigurationItem from './Item';
import {
  findComponentByIdentifier,
  findFirstSelectedOptionByComponentIdentifier,
  findOptionByIdentifier
} from '../Utils/FindInConfiguration';
import {
  ATTRIBUTE_KEY_HAS_ADDONS,
  CATEGORY_ATTRIBUTE_KEY_SOFTWARE,
  COMPONENT_HARDDISK_IDENTIFIER,
  HARDDISK_COMPONENT_IDENTIFIERS
} from '../Constants/Constants';
import { darkBlue, darkGray, red } from '../Constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { getOptionLabel } from '../Utils/getOptionLabel';

interface IConfigurationInfo {
  // eslint-disable-next-line no-unused-vars
  onImageLoaded?: (width: number, height: number) => void;
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: '0px !important',
      [theme.breakpoints.up('md')]: {
        overflow: 'inherit !important',
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '16px !important',
        width: 'fit-content !important'
      }
    },
    line: {
      height: 1,
      background: darkGray
    },
    hints: {
      color: `${red}!important`,
      fontWeight: 'bold !important'
    },
    componentGroup: {
      color: `${darkGray} !important`,
      borderBottom: 'none !important',
      textTransform: 'uppercase',
      paddingLeft: '0px !important'
    },
    componentTitle: {
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      color: `${darkGray} !important`,
      borderBottom: 'none !important',
      padding: '8px 16px 8px 0px  !important',
      paddingLeft: '0px !important'
    },
    selectedOptions: {
      fontSize: '14px',
      borderBottom: 'none !important',
      padding: '8px 0px 8px 16px !important',
      whiteSpace: 'pre-wrap',
      color: `${darkGray} !important`
    }
  };
});

const ConfigurationInfo: FC<IConfigurationInfo> = ({
  onImageLoaded
}): ReactElement | null => {
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const selectedOptions: ISelectedOptions | undefined =
    configuration?.selectedOptions;
  const groupedComponents: IGroupedComponents | undefined = useAppSelector(
    (state) => state.configuration.groupedComponents
  );
  const operatingSystems: any = useAppSelector(
    (state) => state.configuration?.operatingSystemComponent.operatingSystem
  );
  const categoryTranslations: any = useAppSelector(
    (state) => state.configuration.categoryTranslations
  );
  const hints: IHint[] | undefined = useAppSelector(
    (state) => state.configuration.hints
  );
  const addons: any = useAppSelector(
    (state) => state.configuration?.operatingSystemComponent.addons
  );
  let selectedOperatingSystem;
  if (operatingSystems) {
    selectedOperatingSystem = findFirstSelectedOptionByComponentIdentifier(
      operatingSystems.identifier,
      selectedOptions,
      configuration
    );
  }

  let hasAddons = false;
  if (selectedOperatingSystem) {
    const hasAddonsAttribute: IAttribute | undefined =
      selectedOperatingSystem.attributes.find(
        (attribute) => ATTRIBUTE_KEY_HAS_ADDONS === attribute.identifier
      );
    if (hasAddonsAttribute && hasAddonsAttribute.values[0]) {
      hasAddons = parseInt(hasAddonsAttribute.values[0].value) === 1;
    }
  }

  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  if (configuration) {
    return (
      <TableContainer className={classes.root}>
        {!isMobile && (
          <Typography
            sx={{
              pb: 2,
              color: darkBlue,
              fontSize: '16px',
              fontWeight: 'bold'
            }}
            variant="h5">
            {t('configurationHeadline')}
          </Typography>
        )}
        <ConfigurationItem
          item={configuration.item}
          onImageLoaded={onImageLoaded}
        />
        {hints && hints.length > 0 && isMobile && (
          <>
            <div className={classes.line} />
            <Box sx={{ pt: 2, pb: 2 }}>
              <Typography variant="h3" className={classes.hints}>
                {t('verify')}
              </Typography>
              {hints.map((hint: IHint, i: number) => (
                <Typography variant="h4" className={classes.hints} key={i}>
                  {t(hint.message)}
                </Typography>
              ))}
            </Box>
          </>
        )}

        <Table>
          <TableBody>
            {groupedComponents &&
              selectedOptions &&
              Object.keys(groupedComponents).map((groupKey: string, i) => {
                const components = groupedComponents[groupKey];
                return (
                  <React.Fragment key={i}>
                    <TableRow
                      sx={{
                        borderTop: `1px solid ${darkGray}`,
                        width: '100%'
                      }}>
                      <TableCell className={classes.componentGroup}>
                        {categoryTranslations[groupKey]}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: 'none !important' }}></TableCell>
                    </TableRow>
                    {groupKey === CATEGORY_ATTRIBUTE_KEY_SOFTWARE && (
                      <>
                        <TableRow>
                          <TableCell
                            className={classes.componentTitle}
                            style={{ verticalAlign: 'top' }}>
                            {operatingSystems?.title}
                          </TableCell>
                          {selectedOptions[operatingSystems?.identifier] ? (
                            <TableCell
                              key={i}
                              className={classes.selectedOptions}>
                              {selectedOptions[
                                operatingSystems?.identifier
                              ].map(
                                (option) =>
                                  getOptionLabel(
                                    findOptionByIdentifier(
                                      option.identifier,
                                      configuration,
                                      operatingSystems?.identifier
                                    ),
                                    operatingSystems?.identifier,
                                    selectedOptions
                                  ) + '\n'
                              )}
                            </TableCell>
                          ) : (
                            <TableCell className={classes.selectedOptions}>
                              {t('noSelection')}
                            </TableCell>
                          )}
                        </TableRow>
                        {hasAddons && (
                          <TableRow>
                            <TableCell
                              className={classes.componentTitle}
                              style={{ verticalAlign: 'top' }}>
                              {addons?.title}
                            </TableCell>
                            {selectedOptions[addons?.identifier] ? (
                              <TableCell
                                key={i}
                                className={classes.selectedOptions}>
                                {selectedOptions[addons?.identifier].map(
                                  (option) =>
                                    getOptionLabel(
                                      findOptionByIdentifier(
                                        option.identifier,
                                        configuration,
                                        addons?.identifier
                                      ),
                                      addons?.identifier,
                                      selectedOptions
                                    ) + '\n'
                                )}
                              </TableCell>
                            ) : (
                              <TableCell className={classes.selectedOptions}>
                                {t('noSelection')}
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </>
                    )}

                    {components.map((component, i) => {
                      if (component.hiddenInFrontend) {
                        return null;
                      } else if (
                        component.identifier === COMPONENT_HARDDISK_IDENTIFIER
                      ) {
                        return (
                          <React.Fragment key={i}>
                            {HARDDISK_COMPONENT_IDENTIFIERS.map((drives, i) => {
                              const component = findComponentByIdentifier(
                                drives,
                                configuration
                              );
                              if (component) {
                                return (
                                  <TableRow key={i}>
                                    <TableCell
                                      className={classes.componentTitle}
                                      style={{ verticalAlign: 'top' }}>
                                      {component?.title}
                                    </TableCell>
                                    <TableCell
                                      key={i}
                                      className={classes.selectedOptions}>
                                      {selectedOptions[drives] &&
                                        selectedOptions[drives].map(
                                          (option) =>
                                            getOptionLabel(
                                              findOptionByIdentifier(
                                                option.identifier,
                                                configuration,
                                                drives
                                              ),
                                              component.identifier,
                                              selectedOptions
                                            ) + '\n'
                                        )}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <TableRow key={i}>
                            <TableCell
                              className={classes.componentTitle}
                              style={{ verticalAlign: 'top' }}>
                              {component?.title}
                            </TableCell>
                            {selectedOptions[component.identifier] ? (
                              <TableCell
                                key={i}
                                className={classes.selectedOptions}>
                                {selectedOptions[component.identifier].map(
                                  (option) =>
                                    getOptionLabel(
                                      findOptionByIdentifier(
                                        option.identifier,
                                        configuration,
                                        component.identifier
                                      ),
                                      component.identifier,
                                      selectedOptions
                                    ) + '\n'
                                )}
                              </TableCell>
                            ) : (
                              <TableCell className={classes.selectedOptions}>
                                {t('noSelection')}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>

        <div className={classes.line} />
        {hints && hints.length > 0 && !isMobile && (
          <>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Typography variant="h3" className={classes.hints}>
                {t('verify')}
              </Typography>
              {hints.map((hint: IHint, i: number) => (
                <Typography variant="h4" className={classes.hints} key={i}>
                  {t(hint.message)}
                </Typography>
              ))}
            </Box>
            <div className={classes.line} />
          </>
        )}
      </TableContainer>
    );
  }
  return null;
};

export default ConfigurationInfo;
