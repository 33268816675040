import { Checkbox, FormGroup, Grid, Typography } from '@mui/material';
import React, { ReactElement, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IComponent, IOption, ISelectedOptions } from '../Interface/Interface';
import DefaultOption from './DefaultOption';
import { switchOptions } from '../Reducers/ConfigurationSlice';
import { makeStyles } from 'tss-react/mui';
import AmountSelect from './AmountSelect';
import {
  findSelectedOptionAmount,
  isOptionSelected
} from '../Utils/FindInConfiguration';
import { blue, lightgray } from '../Constants/Colors';
import { IOptionCheckFunc } from './DefaultComponent';

interface IMultiSelectComponent {
  component: IComponent;
  optionFilterFunction?: (options: IOption[]) => IOption[];
  checkOptionDisabled?: IOptionCheckFunc;
  hint?: string;
}

const Components: FC<IMultiSelectComponent> = ({
  component,
  optionFilterFunction,
  checkOptionDisabled,
  hint
}): ReactElement => {
  const useStyles = makeStyles()((theme) => {
    return {
      grid: {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(3.125, 4.375, 2.5, 4.375)}`,
        backgroundColor: lightgray,
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(3.125, 1.5, 2.5, 1.5)}`
        }
      }
    };
  });

  const { classes } = useStyles();
  const { title } = component;
  let options = component.options;

  if (optionFilterFunction) {
    options = optionFilterFunction(options);
  }

  const dispatch = useAppDispatch();

  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const itemIdentifier: string | undefined = useAppSelector(
    (state) => state.configuration.configuration?.item.identifier
  );

  const onOptionChange = (optionIdentifier: string) => {
    // @ts-ignore
    dispatch(
      switchOptions(
        component.identifier,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions
      )
    );
  };

  const onOptionAmountChange = (optionIdentifier: string, amount: number) => {
    // @ts-ignore

    dispatch(
      switchOptions(
        component.identifier,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions,
        amount
      )
    );
  };

  return (
    <Grid className={classes.grid}>
      <Typography sx={{ pb: 1 }} variant="h2">
        {title}
      </Typography>
      {hint && <Typography sx={{ color: blue, pb: 1 }}>{hint}</Typography>}
      <FormGroup>
        {options.map((option, index) =>
          option.amount_is_selectable ? (
            <DefaultOption
              onChange={onOptionChange}
              key={index}
              option={option}
              disabled={checkOptionDisabled && checkOptionDisabled(option)}
              control={
                <AmountSelect
                  option={option}
                  onAmountChanged={onOptionAmountChange}
                  amount={findSelectedOptionAmount(
                    option,
                    component.identifier,
                    selectedOptions
                  )}
                />
              }
            />
          ) : (
            <DefaultOption
              onChange={onOptionChange}
              key={index}
              option={option}
              disabled={checkOptionDisabled && checkOptionDisabled(option)}
              control={
                <Checkbox
                  checked={isOptionSelected(
                    option,
                    component.identifier,
                    selectedOptions
                  )}
                  sx={{ pointerEvents: 'auto' }}
                />
              }
            />
          )
        )}
      </FormGroup>
    </Grid>
  );
};

export default Components;
