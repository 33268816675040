import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React, { FC, ReactElement, useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: 3
    }
  }
};

interface IComponentProps {
  filter: any;
  onFilterChanged: (filterIdentifier: string, selectedFilters: any) => void;
  activeFilters: any[];
}

const FilterSelect: FC<IComponentProps> = ({
  filter,
  onFilterChanged,
  activeFilters
}): ReactElement => {
  const useStyles = makeStyles()((theme) => {
    return {
      label: {
        position: 'relative',
        overflow: 'visible !important',
        fontSize: '14px !important',

        '&.Mui-focused, &.MuiInputLabel-shrink': {
          '&:after': {
            content: '""',
            position: 'absolute',
            left: '-5px',
            right: 0,
            bottom: 0,
            height: 'calc(50% + 2px)',
            width: 'calc(100% + 10px)',
            background: '#fff',
            zIndex: '-1'
          }
        }
      },
      select: {
        borderRadius: '3px !important',
        background: '#fff',
        height: '48px',
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: 1.5
        },
        '& svg': {
          fill: theme.palette.primary.main
        }
      },
      formControl: {
        width: '25% !important',
        [theme.breakpoints.down('sm')]: {
          marginTop: '24px !important',
          width: '100% !important'
        }
      }
    };
  });
  const { classes } = useStyles();

  const [selectedFilters, setSelectedFilters] = useState<any[] | null>(null);

  useEffect(() => {
    if (!selectedFilters) return;

    onFilterChanged(filter.identifier, selectedFilters);

    // eslint-disable-next-line
  }, [selectedFilters]);

  useEffect(() => {
    if (!activeFilters) {
      setSelectedFilters(null);
    } else if (activeFilters[filter.identifier]) {
      setSelectedFilters(activeFilters[filter.identifier]);
    }
  }, [activeFilters, filter]);

  const handleChange = (event: any) => {
    const {
      target: { value }
    } = event;

    let duplicateRemoved: any[] = [];

    value.forEach((item: any) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id !== item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setSelectedFilters(duplicateRemoved);
  };

  return (
    <FormControl
      className={classes.formControl}
      fullWidth
      key={filter.identifier}>
      <InputLabel
        id={`${filter.translated_title}-label`}
        className={classes.label}>
        {filter.translated_title}
      </InputLabel>
      <Select
        labelId={`${filter.translated_title}-label`}
        id={`${filter.translated_title}`}
        name={`${filter.identifier}`}
        multiple
        value={selectedFilters || []}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) =>
          selected
            ? selected.map((x: any) => x.translated_value).join(', ')
            : null
        }
        className={classes.select}
        MenuProps={MenuProps}>
        {filter.filterValues?.map((value: any) => {
          return (
            <MenuItem
              key={`${filter.identifier}-${value.translated_value}`}
              value={value as any}>
              <Checkbox
                checked={
                  selectedFilters
                    ? selectedFilters.findIndex(
                        (item: any) => item?.id === value.id
                      ) >= 0
                    : false
                }
              />
              <ListItemText primary={value.translated_value} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FilterSelect;
