import { Grid, List, Radio, RadioGroup, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';
import { t } from 'react-i18nify';
import { makeStyles } from 'tss-react/mui';
import { blue, lightgray } from '../Constants/Colors';
import { ATTRIBUTE_KEY_COMPONENT_FILTERS } from '../Constants/Constants';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  IAttribute,
  IComponent,
  IConfiguration,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import { switchOptions } from '../Reducers/ConfigurationSlice';
import { filterOptions } from '../Utils/filterUtils';
import {
  findFirstSelectedOptionByComponentIdentifier,
  findSelectedOptionAmount,
  getAttribute
} from '../Utils/FindInConfiguration';
import { isSmartPhoneSize } from '../Utils/isMobile';
import AmountSelect from './AmountSelect';
import DefaultOption from './DefaultOption';
import FilterRow from './FilterRow';
import TextButton from './TextButton';

export interface IComponentProps {
  component: IComponent;
  checkOptionDisabled?: IOptionCheckFunc;
  optionLabel?: IOptionLabelFunc;
  disableAmountSelection?: boolean;
  allowZeroAmount?: boolean;
  disableAmountIncrease?: boolean;
  hint?: string;
  optionFilterFunction?: (options: IOption[]) => IOption[];
  determineAmountFunction?: IDetermineAmountFunc;
  minAmount?: number;
}

export interface IOptionCheckFunc {
  // eslint-disable-next-line no-unused-vars
  (option: IOption): boolean;
}

export interface IOptionLabelFunc {
  // eslint-disable-next-line no-unused-vars
  (option: IOption): string;
}

export interface IDetermineAmountFunc {
  (optionIdentifier: string, amount: number, selectedAmount: number): number;
}

const DefaultComponent: FC<IComponentProps> = ({
  component,
  hint,
  checkOptionDisabled,
  optionLabel,
  disableAmountSelection = false,
  allowZeroAmount,
  disableAmountIncrease = false,
  optionFilterFunction,
  determineAmountFunction = undefined,
  minAmount = 1
}): ReactElement => {
  const useStyles = makeStyles()((theme) => {
    return {
      grid: {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(3.125, 4.375, 2.5, 4.375)}`,
        backgroundColor: lightgray,
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(3.125, 1.5, 2.5, 1.5)}`
        }
      }
    };
  });
  const { classes } = useStyles();
  const isMobile = isSmartPhoneSize();

  const { title } = component;
  let options = component.options;

  if (optionFilterFunction) {
    options = optionFilterFunction(options);
  }

  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );

  const itemIdentifier: string | undefined = useAppSelector(
    (state) => state.configuration.configuration?.item.identifier
  );

  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );

  const selectedOption: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      component.identifier,
      selectedOptions,
      configuration
    );

  const componentFilterAttribute: IAttribute | undefined = getAttribute(
    component,
    ATTRIBUTE_KEY_COMPONENT_FILTERS
  );

  const dispatch = useAppDispatch();

  const [activeFilters, setActiveFilters] = useState<any>(null);
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);

  const onOptionChange = (optionIdentifier: string) => {
    let amount = 1;
    // @ts-ignore
    amount = determineAmount(selectedOption, optionIdentifier, amount);
    // @ts-ignore
    dispatch(
      switchOptions(
        component.identifier,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions,
        amount
      )
    );
  };

  const onOptionAmountChange = (optionIdentifier: string, amount: number) => {
    // @ts-ignore
    amount = determineAmount(selectedOption, optionIdentifier, amount);
    // @ts-ignore
    dispatch(
      switchOptions(
        component.identifier,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions,
        amount
      )
    );
  };

  const determineAmount = (
    selectedOption: IOption,
    optionIdentifier: string,
    amount: number
  ): number => {
    const selectedAmount = findSelectedOptionAmount(
      selectedOption,
      component.identifier,
      selectedOptions
    );
    if (determineAmountFunction) {
      return determineAmountFunction(optionIdentifier, amount, selectedAmount);
    }
    return selectedAmount > 1 && selectedOption.identifier !== optionIdentifier
      ? selectedAmount
      : amount;
  };

  const radioGroupValue =
    selectedOptions && selectedOptions[component.identifier]
      ? selectedOptions[component.identifier][0].identifier
      : null;

  const query = new URLSearchParams(location.search);
  const adminMode = query.get('_adminmode');

  const resetToDefault = useCallback(() => {
    setActiveFilters(null);
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (isEmpty(activeFilters)) {
      resetToDefault();
      return;
    }

    const filtered = filterOptions(options, activeFilters);
    setFilteredOptions(filtered);
  }, [
    activeFilters,
    componentFilterAttribute,
    options,
    resetToDefault,
    component
  ]);

  return (
    <>
      <Grid className={classes.grid}>
        <Typography variant="h2">{title}</Typography>
        {hint && <Typography sx={{ color: blue, pb: 1 }}>{hint}</Typography>}

        {activeFilters && !isMobile && (
          <TextButton
            text={t('resetFilter')}
            onClick={() => resetToDefault()}
          />
        )}

        {componentFilterAttribute && (
          <FilterRow
            component={component}
            onSelectedFiltersChanged={(filters: any) =>
              setActiveFilters(filters)
            }
            activeFilters={activeFilters}
            resetActiveFilters={() => resetToDefault()}
          />
        )}

        {filteredOptions.length === 0 && (
          <Typography variant="body1" color={'red'}>
            {t('noFilterResult')}
          </Typography>
        )}

        {filteredOptions.length ? (
          <RadioGroup
            sx={{
              paddingTop: options.length === 1 ? '9px' : '0px'
            }}
            value={radioGroupValue}>
            {filteredOptions.map((option, index) =>
              option.amount_is_selectable && !disableAmountSelection ? (
                <DefaultOption
                  onChange={onOptionChange}
                  key={index}
                  option={option}
                  disabled={checkOptionDisabled && checkOptionDisabled(option)}
                  optionLabel={optionLabel}
                  control={
                    <AmountSelect
                      key={index}
                      disableDecreaseBtn={allowZeroAmount}
                      disableIncreaseBtn={disableAmountIncrease}
                      onAmountChanged={onOptionAmountChange}
                      option={option}
                      amount={findSelectedOptionAmount(
                        option,
                        component.identifier,
                        selectedOptions
                      )}
                      minAmount={minAmount}
                    />
                  }
                />
              ) : (
                <DefaultOption
                  onChange={onOptionChange}
                  key={index}
                  option={option}
                  optionLabel={optionLabel}
                  disabled={checkOptionDisabled && checkOptionDisabled(option)}
                  control={
                    !adminMode && options.length === 1 ? (
                      <List
                        sx={{ paddingTop: '20px', backgroundColor: 'red' }}
                      />
                    ) : (
                      <Radio
                        sx={{
                          padding: '9px 0px 9px 0px',
                          pointerEvents: 'auto'
                        }}
                        key={index}
                      />
                    )
                  }
                />
              )
            )}
          </RadioGroup>
        ) : null}
      </Grid>
    </>
  );
};

export default DefaultComponent;
