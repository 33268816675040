import Api from './Api';
import _ from 'lodash';
import { initLanguage } from './Translation';

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const DEVELOPMENT_HOST_INT = 'http://int.tarox.local';

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const DEVELOPMENT_HOST_LOCAL = 'http://localhost:10740';

const hostsByNodeEnv = {
  production: '',
  development: DEVELOPMENT_HOST_INT,
  test: ''
};

// eslint-disable-next-line no-undef
const host = hostsByNodeEnv[process.env.NODE_ENV];

export const network = {
  host,
  baseURL: `${host}/frontendapi/`,
  timeout: 60000
};

export function init() {
  Api.init(network);

  setLanguage();
  getQueryParams();
}

export function getQueryParams() {
  const query = new URLSearchParams(location.search);

  const queryConf = {};

  // if the language is in the params, set it from there
  if (query.has('_language')) {
    queryConf.language = query.get('_language').replace('_', '-');
  }

  if (query.has('_channel')) {
    const channel = query.get('_channel');
    queryConf.channel = channel;
    Api.setChannel(channel);
  }

  if (query.has('kdnumm')) {
    Api.setGETParameter('kdnumm', encodeURIComponent(query.get('kdnumm')));
  }

  if (query.has('at')) {
    Api.setGETParameter('at', encodeURIComponent(query.get('at')));
  }

  if (query.has('_share_url')) {
    Api.setGETParameter(
      '_share_url',
      encodeURIComponent(query.get('_share_url'))
    );
  }

  if (query.has('_frombasket')) {
    queryConf.fromBasket = query.get('_frombasket') === '1';
  }
  if (query.has('_admin_mode_hash')) {
    Api.setGETParameter('_admin_mode', query.get('_admin_mode_hash'));
  }

  if (query.has('_admin_mode_hash') || query.has('_adminmode')) {
    if (!query.has('kdnumm')) {
      Api.setGETParameter('kdnumm', encodeURIComponent('9300914'));
    }
    if (!query.has('at')) {
      Api.setGETParameter(
        'at',
        encodeURIComponent(
          '1ae1827abc58c5e98212c3d741427a41bf4020a72d37ffe716fc737fd40cfa04'
        )
      );
    }
  }

  if (query.has('_client')) {
    Api.setGETParameter('_client', query.get('_client'));
  }

  if (
    query.has('_display_net_prices') &&
    query.get('_display_net_prices') === '1'
  ) {
    queryConf.displayNetPrices = true;
    Api.setGETParameter(
      '_display_net_prices',
      query.get('_display_net_prices')
    );
  }

  if (query.has('_cta_element')) {
    Api.setGETParameter('_cta_element', query.get('_cta_element'));
  }

  if (query.has('_hide_prices')) {
    Api.setGETParameter('_hide_prices', query.get('_hide_prices'));
  }

  if (query.has('_switch_options')) {
    const optionsToSwitchJSON = query.get('_switch_options');
    try {
      queryConf.selectOptions = JSON.parse(optionsToSwitchJSON);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'Wrong JSON provided for _switch_options:',
        optionsToSwitchJSON
      );
    }

    Api.setGETParameter('_switch_options', optionsToSwitchJSON);
  }

  return queryConf;
}

function setLanguage() {
  // the available locales
  const locales = [{ code: 'de_DE', preferred: true }, { code: 'en_GB' }];
  const query = new URLSearchParams(location.search);
  let language;
  let browserLanguage;
  if (query.has('_language')) {
    browserLanguage = query.get('_language');
  } else {
    browserLanguage = _.get(
      navigator,
      'languages[0]',
      navigator.language
    ).replace('-', '_');
  }
  const matchToBrowserLanguage = _.find(locales, { code: browserLanguage });
  const preferred = _.find(locales, { preferred: true });

  // if there is a match with the browser language use that
  if (matchToBrowserLanguage) {
    language = matchToBrowserLanguage.code;
  }
  // if the preferred language is set use it
  else if (preferred) {
    language = preferred.code;
  }
  // otherwise use the first from the locales list
  else {
    language = _.first(locales).code;
  }
  language = language.replace('_', '-');
  Api.setLanguage(language);
  initLanguage(language);
}

export function isProduction() {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === 'production';
}
