import { Dialog, DialogProps, Paper, PaperProps } from '@mui/material';
import React from 'react';

export type ContainerPositionContextType = {
  height: number;
  width: number;
  scrollTop: number;
  iframeOffset: number;
};

const defaultContainerPositionContextValue = {
  height: 0,
  width: 0,
  scrollTop: 0,
  iframeOffset: 0
};

export const ContainerPositionContext =
  React.createContext<ContainerPositionContextType>(
    defaultContainerPositionContextValue
  );

export const ContainerPositionProvider = (props: React.ProviderProps<any>) => {
  const [positionInfo, setPositionInfo] = React.useState(
    defaultContainerPositionContextValue
  );
  React.useEffect(() => {
    // own window mode
    if (!window.parent || window.parent === window) {
      const updateLocalPositionInfo = () => {
        const scrollTop = 0;
        const iframeOffset = 0;
        const width = window.innerWidth;
        const height = window.innerHeight;
        const dimensions = {
          height,
          width,
          scrollTop,
          iframeOffset
        };
        setPositionInfo(dimensions);
      };

      window.addEventListener('scroll', updateLocalPositionInfo);
      window.addEventListener('resize', updateLocalPositionInfo);
      updateLocalPositionInfo();

      return () => {
        window.removeEventListener('scroll', updateLocalPositionInfo);
        window.removeEventListener('resize', updateLocalPositionInfo);
      };
    }

    // iframe mode
    const messageListener = (event: MessageEvent) => {
      if (event.data.type === 'containerWindowState') {
        const dimensions = {
          height: event.data.containerWindowHeight,
          width: event.data.containerWindowWidth,
          scrollTop: event.data.scrollTop,
          iframeOffset: event.data.iframeOffset
        };
        setPositionInfo(dimensions);
      }
    };

    window.addEventListener('message', messageListener);
    window.parent.postMessage({ type: 'getContainerWindowState' }, '*');

    return () => window.removeEventListener('message', messageListener);
  }, []);

  return <ContainerPositionContext.Provider {...props} value={positionInfo} />;
};

export const useAbsolutePosition = (target: 'middle' | 'bottom') => {
  const positionInfo = React.useContext(ContainerPositionContext);
  switch (target) {
    case 'middle':
      return (
        positionInfo.scrollTop +
          positionInfo.height * 0.5 -
          positionInfo.iframeOffset || 0
      );
    case 'bottom':
      return (
        positionInfo.scrollTop +
          positionInfo.height -
          positionInfo.iframeOffset || 0
      );
    default:
      return 0;
  }
};

export const useAbsolutePositionInfo = () =>
  React.useContext(ContainerPositionContext);

export const useAbsolutePositionStyle = (): React.CSSProperties => {
  const absoluteMiddle = useAbsolutePosition('middle');
  return {
    position: 'absolute',
    top: absoluteMiddle,
    transform: 'translateY(-50%)'
  };
};

export const AbsolutePaper = ({ style, ...props }: PaperProps) => {
  const absolutePositionStyle = useAbsolutePositionStyle();
  return <Paper {...props} style={{ ...style, ...absolutePositionStyle }} />;
};

export const AbsoluteDialog = (props: DialogProps) => (
  <Dialog {...props} PaperComponent={AbsolutePaper} />
);

export const useContainerSizeUpdate = (marginX = 0, marginY = 0) => {
  const sizeRef = React.useRef({ width: 0, height: 0 });
  const mutationObserverRef = React.useRef<MutationObserver>();

  const updateConfiguratorContainerSize = React.useCallback(
    (containerNode: HTMLElement) => {
      const width = (containerNode?.scrollWidth || 0) + marginX;
      const height = (containerNode?.scrollHeight || 0) + marginY;
      if (
        width !== sizeRef.current.width ||
        height !== sizeRef.current.height
      ) {
        sizeRef.current = {
          width,
          height
        };
        window.parent?.postMessage(
          {
            type: 'configuratorContainerSize',
            width,
            height
          },
          '*'
        );
      }
    },
    [marginX, marginY]
  );

  const setRootRef = React.useCallback(
    (node: HTMLElement) => {
      if (node && (!window.parent || window.parent === window)) {
        console.log(
          'Configurator is opened in its own window, skip container size update.'
        );
        return;
      }
      if (node) {
        mutationObserverRef.current?.disconnect();
        updateConfiguratorContainerSize(node);
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver(() =>
          updateConfiguratorContainerSize(node)
        );
        observer.observe(node, config);
        mutationObserverRef.current = observer;
      }
    },
    [updateConfiguratorContainerSize]
  );

  return {
    setRootRef
  };
};
