import React, { ReactElement, FC } from 'react';
import {
  IAttribute,
  IComponent,
  IConfiguration,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import { useAppSelector } from '../hooks';
import { findFirstSelectedOptionByComponentIdentifier } from '../Utils/FindInConfiguration';
import {
  ATTRIBUTE_KEY_ONBOARD_RAID_CONTROLLER,
  COMPONENT_MAINBOARD_IDENTIFIER,
  OPTION_NO_RAID_CONTROLLER_IDENTIFIER,
  OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER
} from '../Constants/Constants';
import DefaultComponent from './DefaultComponent';

interface IComponentProps {
  component: IComponent;
}

const RaidControllerComponent: FC<IComponentProps> = ({
  component
}): ReactElement => {
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const numSelectedRaidControllers: number = useAppSelector(
    (state) => state.configuration.numSelectedRaidControllers
  );
  const selectedMainboard: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_MAINBOARD_IDENTIFIER,
      selectedOptions,
      configuration
    );
  let mainboardHasOnboardRaid = false;
  if (selectedMainboard) {
    const onboardRaidAttribute: IAttribute | undefined =
      selectedMainboard.attributes.find(
        (attribute) =>
          ATTRIBUTE_KEY_ONBOARD_RAID_CONTROLLER === attribute.identifier
      );
    if (onboardRaidAttribute && onboardRaidAttribute.values[0]) {
      mainboardHasOnboardRaid =
        onboardRaidAttribute.values[0].translations &&
        onboardRaidAttribute.values[0].translations.length > 0
          ? Boolean(onboardRaidAttribute.values[0].translated_value)
          : Boolean(onboardRaidAttribute.values[0].value);
    }
  }

  const isOptionDisabled = (option: IOption) => {
    return (
      (!mainboardHasOnboardRaid &&
        option.identifier === OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER) ||
      (mainboardHasOnboardRaid &&
        option.identifier === OPTION_NO_RAID_CONTROLLER_IDENTIFIER)
    );
  };

  return (
    <DefaultComponent
      component={component}
      checkOptionDisabled={isOptionDisabled}
      disableAmountIncrease={numSelectedRaidControllers >= 2}
    />
  );
};

export default RaidControllerComponent;
