import React, { ReactElement, FC, SyntheticEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector, useAppDispatch } from '../hooks';
import { t } from 'react-i18nify';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogContent,
  DialogActions,
  Tooltip,
  Box,
  Typography
} from '@mui/material';
import { darkBlue, green, white } from '../Constants/Colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { saveConfigurationForSharing } from '../Reducers/ConfigurationSlice';
import {
  IConfiguration,
  IHint,
  ISelectedOptions
} from '../Interface/Interface';
import ValidationErrorDialog from './ValidationErrorDialog';
import { AbsoluteDialog } from './ContainerPositionProvider';

const useStyles = makeStyles()((theme) => {
  return {
    MuiDialogBorder: {
      borderTop: '7px solid ' + theme.palette.primary.main
    },
    dialogTitleRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '15px'
    },
    dialogContentRoot: {
      padding: '0 24px 0 12px'
    },
    okButton: {
      padding: '6px 15px !important',
      width: '20%'
    },
    closeIcon: {
      position: 'absolute',
      right: 28,
      top: 28
    },
    saveConfigurationCode: {
      textAlign: 'center',
      marginTop: 24,
      marginBottom: 48,
      height: 40,
      fontSize: 24,
      lineHeight: '28px',
      [theme.breakpoints.down('xs')]: {
        height: 56,
        marginBottom: 32
      }
    },
    successResult: {
      background: green,
      color: white,
      padding: '14px 8px',
      borderRadius: '4px'
    },
    successResultIcon: {
      float: 'left'
    },
    copyShareUrlIcon: {
      float: 'right',
      '&:hover': {
        color: 'rgba(0,0,0, 0.5)',
        cursor: 'pointer'
      }
    }
  };
});

const SaveConfiguration: FC = (): ReactElement => {
  const shareUrl: string | undefined = useAppSelector(
    (state) => state.configuration.shareUrl
  );
  const shareCode: string | undefined = useAppSelector(
    (state) => state.configuration.shareCode
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const hints: IHint[] | undefined = useAppSelector(
    (state) => state.configuration.hints
  );
  const hasHints: boolean = hints ? hints.length > 0 : false;

  const hasDeletedOptionsSelected: boolean = useAppSelector(
    (state) =>
      state.configuration.configuration?.hasDeletedOptionsSelected || false
  );

  const selectedOptions: ISelectedOptions | undefined =
    configuration?.selectedOptions;
  const [saveOpen, setSaveOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const handleSaveClickOpen = (event: SyntheticEvent) => {
    // eslint-disable-next-line prettier/prettier
    if(hasHints || hasDeletedOptionsSelected){
      setErrorDialogOpen(true);
    } else {
      event.preventDefault();
      if (configuration && selectedOptions) {
        dispatch(
          saveConfigurationForSharing(
            configuration.item.identifier,
            selectedOptions
          )
        );
      }
      setSaveOpen(true);
    }
  };
  const handleClose = () => {
    setSaveOpen(false);
  };
  const onCopyShareUrl = (shareUrl: string) => {
    navigator.clipboard.writeText(shareUrl);
  };
  const { classes } = useStyles();
  return (
    <>
      <Button onClick={handleSaveClickOpen} sx={{ color: darkBlue }}>
        <CloudDownloadIcon sx={{ pr: 1 }} />
        <Typography>{t('save')}</Typography>
      </Button>
      {errorDialogOpen ? (
        <ValidationErrorDialog
          title={
            hasDeletedOptionsSelected
              ? 'deletedOptionsSelectedTitle'
              : undefined
          }
          errorMessage={
            hasDeletedOptionsSelected
              ? 'deletedOptionsSelectedErrorMessage'
              : undefined
          }
          open={errorDialogOpen}
          handleClose={() => setErrorDialogOpen(false)}
        />
      ) : (
        <AbsoluteDialog
          classes={{
            paper: classes.MuiDialogBorder
          }}
          open={saveOpen}
          onClose={handleClose}>
          <Box className={classes.dialogTitleRoot}>
            <Typography variant="h1">{t('saveConfiguration')}</Typography>

            <CloseIcon onClick={handleClose} />
          </Box>

          <DialogContent className={classes.dialogContentRoot}>
            {shareUrl && (
              <React.Fragment>
                <Typography variant="h4" sx={{ p: 1 }}>
                  {t('saveSuccessfullySaved')}
                </Typography>
                <Typography variant="h4" sx={{ p: 1 }}>
                  {t('saveConfigurationInfoText')}
                </Typography>
                <Typography
                  variant="h4"
                  classes={{ root: classes.saveConfigurationCode }}>
                  <div className={classes.successResult}>
                    <CheckCircleIcon className={classes.successResultIcon} />
                    <b>{shareCode}</b>

                    <Tooltip title={t('copyConfigurationLink')}>
                      <FileCopyIcon
                        className={classes.copyShareUrlIcon}
                        onClick={() => onCopyShareUrl(shareUrl)}
                      />
                    </Tooltip>
                  </div>
                </Typography>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            {shareUrl && (
              <Button
                onClick={handleClose}
                variant="contained"
                size="medium"
                className={classes.okButton}>
                {t('okText')}
              </Button>
            )}
          </DialogActions>
        </AbsoluteDialog>
      )}
    </>
  );
};

export default SaveConfiguration;
