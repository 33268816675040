import { Button, ButtonGroup, Input } from '@mui/material';
import React from 'react';
import { FC, ReactElement } from 'react';
import { IOption } from '../Interface/Interface';
import { makeStyles } from 'tss-react/mui';
import { blue, darkGray, disabledGray } from '../Constants/Colors';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface IAmountSelect {
  option: IOption;
  // eslint-disable-next-line no-unused-vars
  onAmountChanged: (optionIdentifier: string, amount: number) => void;
  amount: number;
  disabled?: boolean;
  disableDecreaseBtn?: boolean;
  disableIncreaseBtn?: boolean;
  minAmount?: number;
}
const AmountSelect: FC<IAmountSelect> = ({
  option,
  onAmountChanged,
  amount,
  disabled = false,
  disableDecreaseBtn = true,
  disableIncreaseBtn = false,
  minAmount = 1
}): ReactElement => {
  const displayCounter = amount > 0;
  const useStyles = makeStyles()((theme) => {
    return {
      Buttongroup: {
        boxShadow: 'none !important',
        padding: theme.spacing(0.75, 0),
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      counterbtn: {
        color: 'black !important',
        border: `1px solid ${blue} !important`,
        backgroundColor: 'transparent !important',
        minWidth: 'auto !important',
        height: '36px !important',
        width: '36px !important'
      },
      input: {
        borderTop: `1px solid ${blue}`,
        pointerEvents: 'auto',
        borderBottom: `1px solid ${blue}`,
        width: '36px',
        height: '36px',
        '& input[type=number]': {
          MozAppearance: 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        },
        '& input': {
          textAlign: 'center'
        }
      }
    };
  });
  const { classes } = useStyles();

  const disableDecrease: boolean = !disableDecreaseBtn && amount <= minAmount;
  return (
    <>
      <ButtonGroup
        disabled={disabled}
        className={classes.Buttongroup}
        variant="contained"
        size="small"
        aria-label="amount select button group">
        {displayCounter ? (
          <Button
            disabled={disableIncreaseBtn}
            className={classes.counterbtn}
            sx={{ pointerEvents: 'auto' }}
            onClick={() => {
              onAmountChanged(option.identifier, amount + 1);
            }}>
            <AddIcon
              sx={{
                fontSize: '1rem',
                color: disableIncreaseBtn ? disabledGray : darkGray
              }}
            />
          </Button>
        ) : (
          <Button
            sx={{
              width: '108px',
              boxShadow: 'none',
              height: '36px',
              pointerEvents: 'auto'
            }}
            onClick={() => {
              onAmountChanged(option.identifier, amount + 1);
            }}>
            <AddIcon sx={{ fontSize: '1rem' }} />
          </Button>
        )}
        {displayCounter && (
          <Input
            className={classes.input}
            disableUnderline
            type="number"
            value={amount}
            onFocus={(e: any) => {
              e.target.select();
            }}
            onChange={(e: any) => {
              // if the user tried to clear the input, select the text so a new number can be entered
              if (e.target.value === '') {
                e.target.value = amount;
                e.target.select();
              } else {
                let intValue = parseInt(e.target.value);
                if (intValue == 0) {
                  intValue = 1;
                  e.target.value = intValue;
                }
                onAmountChanged(option.identifier, intValue);
              }
            }}
          />
        )}
        {displayCounter && (
          <Button
            disabled={disableDecrease}
            className={classes.counterbtn}
            sx={{ pointerEvents: 'auto' }}
            onClick={() => {
              onAmountChanged(option.identifier, amount - 1);
            }}>
            <RemoveIcon
              sx={{
                fontSize: '1rem',
                color: disableDecrease ? disabledGray : darkGray
              }}
            />
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};
export default AmountSelect;
