import { createSlice } from '@reduxjs/toolkit';
import { Api } from '../Api';
import { AppDispatch } from '../store';
import {
  IAttribute,
  IConfiguration,
  IGroupedComponents,
  ISelectedOptions,
  IHarddiskGroupComponent,
  IRaidLevelComponent,
  IOperatingSystemComponent,
  IHint
} from '../Interface/Interface';
import {
  ATTRIBUTE_KEY_CATEGORY,
  ATTRIBUTE_KEY_DRIVE_TYPE,
  ATTRIBUTE_VALUE_HDD,
  ATTRIBUTE_VALUE_SSD,
  CATEGORY_ATTRIBUTE_KEY_COMPONENTS,
  COMPONENT_HARDDISK_IDENTIFIER,
  COMPONENT_OPERATING_SYSTEM_ADDONS_IDENTIFIER,
  COMPONENT_RAID_CONTROLLER_IDENTIFIER,
  COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
  COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
  COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
  HARDDISK_COMPONENT_IDENTIFIERS,
  ATTRIBUTE_VALUE_NVME,
  ATTRIBUTE_KEY_BUS_TYPE
} from '../Constants/Constants';
import {
  findComponentByIdentifier,
  getNumberOfSelectedDrives,
  getNumberOfSelectedOptions
} from '../Utils/FindInConfiguration';
import { METHOD_ADD_TO_CART } from '../Utils/createMessageInterface';
import {
  getAllowedRaidLevels,
  getHDDs,
  getM2s,
  getNvmes,
  getPriceField,
  getRaidLevelOptions,
  getSSDs,
  isOperatingSystem,
  sortOptionsByPrice
} from './ConfigurationSetupHelper';

export interface ConfigurationState {
  configuration?: IConfiguration;
  groupedComponents?: IGroupedComponents;
  harddiskComponent?: IHarddiskGroupComponent;
  operatingSystemComponent: IOperatingSystemComponent;
  errorMessage?: string | undefined;
  hints?: IHint[];
  isLoading: boolean;
  categoryTranslations: any;
  shareUrl?: string;
  shareCode?: string;
  allowedRaidLevels: { [key: string]: string[] };
  numSelectedRaidControllers: number;
}

const initialState: ConfigurationState = {
  configuration: undefined,
  groupedComponents: undefined,
  harddiskComponent: undefined,
  operatingSystemComponent: {},
  errorMessage: undefined,
  hints: undefined,
  isLoading: false,
  categoryTranslations: {},
  shareUrl: undefined,
  allowedRaidLevels: {},
  numSelectedRaidControllers: 0
};

export const authSlice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    loadConfigurationSuccess: (state, action) => {
      state.configuration = action.payload;
      // @ts-ignore
      const groupedComponents: IGroupedComponents = [];
      const harddiskComponent: IHarddiskGroupComponent | undefined = {
        identifier: COMPONENT_HARDDISK_IDENTIFIER,
        title: '',
        description: '',
        attributes: [],
        isMandatory: false,
        sequenceNumber: 0,
        hiddenInFrontend: false,
        options: []
      };
      const priceField = getPriceField();
      state.configuration?.components.map((component) => {
        const categoryAttribute: IAttribute | undefined =
          component.attributes.find(
            (attribute) => ATTRIBUTE_KEY_CATEGORY === attribute.identifier
          );
        let category: string | undefined;
        if (categoryAttribute && categoryAttribute.values[0]) {
          category = categoryAttribute.values[0].value as string;
          if (!groupedComponents[category]) {
            groupedComponents[category] = [];
            state.categoryTranslations[category] =
              categoryAttribute.values[0].translated_value;
          }
        }

        // sort options by price ascending
        if (component.identifier !== COMPONENT_RAID_LEVEL_HDD_IDENTIFIER) {
          sortOptionsByPrice(component, priceField);
        }

        if (component.identifier === COMPONENT_RAID_CONTROLLER_IDENTIFIER) {
          if (state.configuration) {
            state.numSelectedRaidControllers = getNumberOfSelectedOptions(
              component,
              state.configuration.selectedOptions
            );
          }
          state.allowedRaidLevels = getAllowedRaidLevels(component);
        }

        if (
          HARDDISK_COMPONENT_IDENTIFIERS.indexOf(component.identifier) !== -1
        ) {
          if (component.identifier === COMPONENT_HARDDISK_IDENTIFIER) {
            groupedComponents[CATEGORY_ATTRIBUTE_KEY_COMPONENTS].push(
              harddiskComponent
            );
            harddiskComponent.sequenceNumber = component.sequenceNumber;
            harddiskComponent.harddisk = {
              harddisks: getHDDs(component),
              ssds: getSSDs(component),
              m2: getM2s(component),
              nvmes: getNvmes(component),
              numSelectedHarddisks: 0,
              numSelectedSSDs: 0,
              numSelectedNvmes: 0
            };
            // eslint-disable-next-line no-prototype-builtins
            if (
              // eslint-disable-next-line no-prototype-builtins
              state.configuration?.selectedOptions.hasOwnProperty(
                COMPONENT_HARDDISK_IDENTIFIER
              )
            ) {
              harddiskComponent.harddisk.numSelectedHarddisks =
                getNumberOfSelectedDrives(
                  harddiskComponent.harddisk.harddisks,
                  state.configuration.selectedOptions[
                    COMPONENT_HARDDISK_IDENTIFIER
                  ],
                  ATTRIBUTE_KEY_DRIVE_TYPE,
                  ATTRIBUTE_VALUE_HDD
                );
              harddiskComponent.harddisk.numSelectedSSDs =
                getNumberOfSelectedDrives(
                  harddiskComponent.harddisk.ssds,
                  state.configuration.selectedOptions[
                    COMPONENT_HARDDISK_IDENTIFIER
                  ],
                  ATTRIBUTE_KEY_DRIVE_TYPE,
                  ATTRIBUTE_VALUE_SSD
                );
              harddiskComponent.harddisk.numSelectedNvmes =
                getNumberOfSelectedDrives(
                  harddiskComponent.harddisk.nvmes,
                  state.configuration.selectedOptions[
                    COMPONENT_HARDDISK_IDENTIFIER
                  ],
                  ATTRIBUTE_KEY_BUS_TYPE,
                  ATTRIBUTE_VALUE_NVME
                );
            }
          } else if (
            component.identifier === COMPONENT_RAID_LEVEL_HDD_IDENTIFIER
          ) {
            component.options = getRaidLevelOptions(component);
            harddiskComponent.raidLevelHarddisk = <IRaidLevelComponent>(
              component
            );
          } else if (
            component.identifier === COMPONENT_RAID_LEVEL_SSD_IDENTIFIER
          ) {
            component.options = getRaidLevelOptions(component);
            harddiskComponent.raidLevelSSD = <IRaidLevelComponent>component;
          } else if (
            component.identifier === COMPONENT_RAID_LEVEL_NVME_IDENTIFIER
          ) {
            component.options = getRaidLevelOptions(component);
            harddiskComponent.raidLevelNvme = <IRaidLevelComponent>component;
          }
        } else if (isOperatingSystem(component)) {
          state.operatingSystemComponent.operatingSystem = component;
        } else if (
          component.identifier === COMPONENT_OPERATING_SYSTEM_ADDONS_IDENTIFIER
        ) {
          state.operatingSystemComponent.addons = component;
        } else if (category && groupedComponents[category]) {
          groupedComponents[category].push(component);
        }
      });
      state.harddiskComponent = harddiskComponent;
      state.groupedComponents = groupedComponents;
    },
    switchOptionsSuccess: (state, action) => {
      if (action.payload.notice && action.payload.notice.message) {
        state.errorMessage = action.payload.notice.message;
      }
      if (state.configuration) {
        state.configuration.selectedOptions = action.payload.selectedOptions;
        state.configuration.calculation = action.payload.calculation;
        state.hints = action.payload.hints;
        const raidController = findComponentByIdentifier(
          COMPONENT_RAID_CONTROLLER_IDENTIFIER,
          state.configuration
        );
        if (raidController) {
          state.numSelectedRaidControllers = getNumberOfSelectedOptions(
            raidController,
            state.configuration.selectedOptions
          );
        }

        // eslint-disable-next-line no-prototype-builtins
        if (
          state.harddiskComponent &&
          state.harddiskComponent.harddisk &&
          // eslint-disable-next-line no-prototype-builtins
          state.configuration?.selectedOptions.hasOwnProperty(
            COMPONENT_HARDDISK_IDENTIFIER
          )
        ) {
          state.harddiskComponent.harddisk.numSelectedHarddisks =
            getNumberOfSelectedDrives(
              state.harddiskComponent.harddisk.harddisks,
              state.configuration.selectedOptions[
                COMPONENT_HARDDISK_IDENTIFIER
              ],
              ATTRIBUTE_KEY_DRIVE_TYPE,
              ATTRIBUTE_VALUE_HDD
            );
          state.harddiskComponent.harddisk.numSelectedSSDs =
            getNumberOfSelectedDrives(
              state.harddiskComponent.harddisk.ssds,
              state.configuration.selectedOptions[
                COMPONENT_HARDDISK_IDENTIFIER
              ],
              ATTRIBUTE_KEY_DRIVE_TYPE,
              ATTRIBUTE_VALUE_SSD
            );
          state.harddiskComponent.harddisk.numSelectedNvmes =
            getNumberOfSelectedDrives(
              state.harddiskComponent.harddisk.nvmes,
              state.configuration.selectedOptions[
                COMPONENT_HARDDISK_IDENTIFIER
              ],
              ATTRIBUTE_KEY_BUS_TYPE,
              ATTRIBUTE_VALUE_NVME
            );
        }
      }
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShareUrl: (state, action) => {
      state.shareUrl = action.payload.shareUrl;
      state.shareCode = action.payload.code;
    }
  }
});

export const {
  loadConfigurationSuccess,
  switchOptionsSuccess,
  setErrorMessage,
  setIsLoading,
  setShareUrl,
  reset
} = authSlice.actions;

export default authSlice.reducer;

export const loadInitialConfiguration = () => (dispatch: AppDispatch) => {
  const query = new URLSearchParams(location.search);
  if (
    (!query.has('kdnumm') || !query.has('at')) &&
    !query.has('_admin_mode_hash') &&
    !query.has('_adminmode')
  ) {
    dispatch(setErrorMessage('error_no_login'));
  } else {
    dispatch(setIsLoading(true));
    const params = {
      identifier: false,
      code: false
    };
    const basePath = '/';
    const itemRegex = new RegExp(`${basePath}(code|identifier):([^/]+)`);
    const results = itemRegex.exec(location.pathname);
    if (results) {
      // @ts-ignore
      params[results[1]] = results[2];
      let urlPart = '';
      if (params.identifier) {
        urlPart = `loadbyitemidentifier/${params.identifier}`;
      } else if (params.code) {
        urlPart = `loadbyconfigurationcode/${params.code}`;
      }
      Api.request(`/configuration/${urlPart}`)
        .then(async (response) => {
          await dispatch(reset());
          return dispatch(loadConfigurationSuccess(response.data));
        })
        .catch((e) => {
          return dispatch(e);
        })
        .finally(() => dispatch(setIsLoading(false)));
    }
  }
};

export const loadbyconfigurationcode =
  (code: string) => async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    let urlPart = '';
    {
      urlPart = `loadbyconfigurationcode/${code}`;
    }
    return Api.request(`/configuration/${urlPart}`)
      .then((response) => {
        return dispatch(loadConfigurationSuccess(response.data));
      })
      .catch((e) => {
        return dispatch(e);
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

export const switchOptions =
  (
    componentIdentifier: string,
    optionIdentifier: string,
    itemIdentifier: string,
    selectedOptions: ISelectedOptions,
    amount = 1,
    userNote = ''
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    const data = {
      itemIdentifier,
      selectedOptions,
      optionToSwitch: {
        componentIdentifier,
        optionIdentifier,
        amount,
        userNote
      }
    };
    Api.request({ url: `configuration/switchoption`, method: 'post', data })
      .then((response) => {
        return dispatch(switchOptionsSuccess(response.data));
      })
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          return dispatch(setErrorMessage(e.response.data.message));
        }
        return;
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

export const saveConfigurationForCart =
  (itemIdentifier: string, selectedOptions: ISelectedOptions, code?: string) =>
  (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    const data = {
      code,
      itemIdentifier,
      configurationType: 'cart',
      selectedOptions
    };

    Api.request({ url: `configurations`, method: 'post', data })
      .then((response) => {
        if (response.data && response.data.code) {
          if (
            window.parent !== undefined &&
            window.parent.postMessage !== undefined
          ) {
            window.parent.postMessage(
              { method: METHOD_ADD_TO_CART, code: response.data.code },
              '*'
            );
          }
        }
      })
      .catch((e) => {
        return dispatch(e);
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

export const saveConfigurationForSharing =
  (itemIdentifier: string, selectedOptions: ISelectedOptions) =>
  (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    const data = {
      code: undefined,
      itemIdentifier,
      configurationType: 'share',
      selectedOptions
    };

    Api.request({ url: `configurations`, method: 'post', data })
      .then((response) => {
        if (response.data && response.data.shareUrl) {
          dispatch(setShareUrl(response.data));
        }
      })
      .catch((e) => {
        return dispatch(e);
      })
      .finally(() => dispatch(setIsLoading(false)));
  };
