import { IOption, ISelectedOptions } from '../Interface/Interface';
import { findSelectedOptionAmount } from './FindInConfiguration';

export const getOptionLabel = (
  option: IOption | undefined,
  componentIdentifier: string,
  selectedOptions: ISelectedOptions | undefined
) => {
  let label = '';
  if (option) {
    label = option.title;
    const amount = findSelectedOptionAmount(
      option,
      componentIdentifier,
      selectedOptions
    );
    if (amount > 1) {
      label = `${amount}x ${label}`;
    }
  }
  return label;
};
