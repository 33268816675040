export const COMPONENT_HARDDISK_IDENTIFIER = 'drive';
export const COMPONENT_RAID_LEVEL_HDD_IDENTIFIER = 'raid_level_hdd';
export const COMPONENT_RAID_LEVEL_SSD_IDENTIFIER = 'raid_level_ssd';
export const COMPONENT_RAID_LEVEL_NVME_IDENTIFIER = 'raid_level_nvme';

export const HARDDISK_COMPONENT_IDENTIFIERS: Array<string> = [
  COMPONENT_HARDDISK_IDENTIFIER,
  COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
  COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
  COMPONENT_RAID_LEVEL_NVME_IDENTIFIER
];

export const COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER =
  'operating_system_server';
export const COMPONENT_OPERATING_SYSTEM_WORKSTATION_IDENTIFIER =
  'operating_system_workstation';
export const COMPONENT_OPERATING_SYSTEM_ADDONS_IDENTIFIER =
  'operating_system_addons';
export const COMPONENT_CPU_IDENTIFIER = 'cpu';
export const COMPONENT_CPU_COOLER_IDENTIFIER = 'cpu_cooler';
export const COMPONENT_RAID_CONTROLLER_IDENTIFIER = 'raid_controller';
export const COMPONENT_MAINBOARD_IDENTIFIER = 'mainboard';
export const COMPONENT_POWER_ADAPTER_IDENTIFIER = 'power_adapter';
export const COMPONENT_GRAPHICS_CARD_IDENTIFIER = 'graphics_card';
export const COMPONENT_RAM_IDENTIFIER = 'ram';

export const OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER =
  'raidcontroller_onboard';
export const OPTION_NO_RAID_CONTROLLER_IDENTIFIER = 'no_raidcontroller';
export const OPTION_NO_RAID_HDD_IDENTIFIER = 'no_raid_hdd';
export const OPTION_NO_RAID_SSD_IDENTIFIER = 'no_raid_ssd';
export const OPTION_NO_RAID_NVME_IDENTIFIER = 'no_raid_nvme';
export const OPTION_NO_OPERATING_SYSTEM_IDENTIFIER = 'no_os';

export const CATEGORY_ATTRIBUTE_KEY_COMPONENTS = 'components';
export const CATEGORY_ATTRIBUTE_KEY_SOFTWARE = 'software';

export const ATTRIBUTE_KEY_CATEGORY = 'category';
export const ATTRIBUTE_KEY_DRIVE_TYPE = 'drive_type';
export const ATTRIBUTE_KEY_RAID_LEVEL_MIN_DISK = 'min_disk_amount';
export const ATTRIBUTE_KEY_RAID_LEVEL = 'raid_level';
export const ATTRIBUTE_KEY_CPU_CORES = 'core_amount';
export const ATTRIBUTE_KEY_ONBOARD_RAID_CONTROLLER =
  'has_onboard_raid_controller';
export const ATTRIBUTE_KEY_THERMAL_DESIGN_POWER = 'tdp';
export const ATTRIBUTE_KEY_BUS_TYPE = 'bus_type';
export const ATTRIBUTE_KEY_FORM_FACTOR = 'form_factor';
export const ATTRIBUTE_KEY_HAS_ADDONS = 'has_addons';
export const ATTRIBUTE_KEY_COMPONENT_FILTERS = 'component_filters';
export const ATTRIBUTE_KEY_ALLOWED_LICENSE_TYPES = 'allowed_license_types';
export const ATTRIBUTE_KEY_LICENSE_TYPE = 'license_type';
export const ATTRIBUTE_KEY_WATTAGE = 'wattage';
export const ATTRIBUTE_PRODUCT_TYPE = 'product_type';

export const ATTRIBUTE_KEY_SLOTS_NVME_25 = '25_slots_capability_nvme';
export const ATTRIBUTE_KEY_SLOTS_NVME_35 = '35_slots_capability_nvme';

export const ATTRIBUTE_RAID_LEVEL_ALLOWED = 'raid_level_allowed';

export const ATTRIBUTE_VALUE_SSD = 'ssd';
export const ATTRIBUTE_VALUE_HDD = 'hdd';
export const ATTRIBUTE_VALUE_SAS = 'sas';
export const ATTRIBUTE_VALUE_M2 = 'm2';
export const ATTRIBUTE_VALUE_NVME = 'nvme';

export const PRODUCT_TYPE_SERVER = 'server';
export const PRODUCT_TYPE_WORKSTATION = 'workstation';
