import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { t } from 'react-i18nify';
import { makeStyles } from 'tss-react/mui';
import { IComponent, IOption } from '../Interface/Interface';
import { generateFilterData } from '../Utils/filterUtils';
import { isSmartPhoneSize } from '../Utils/isMobile';
import FilterSelect from './FilterSelect';
import TextButton from './TextButton';

interface IComponentProps {
  component: IComponent;
  onSelectedFiltersChanged: (selectedFilters: any) => void;
  activeFilters: any;
  resetActiveFilters?: () => void;
  availableOptions?: IOption[] | undefined;
}

const FilterRow: FC<IComponentProps> = ({
  component,
  onSelectedFiltersChanged,
  activeFilters,
  resetActiveFilters,
  availableOptions
}): ReactElement => {
  const isMobile = isSmartPhoneSize();

  const useStyles = makeStyles()((theme) => {
    return {
      stack: {
        justifyContent: 'start !important',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      },
      filterRow: !isMobile
        ? {}
        : {
            display: 'none',
            background: 'white',
            position: 'fixed',
            top: theme.spacing(3),
            left: theme.spacing(3),
            right: theme.spacing(3),
            bottom: theme.spacing(3),
            zIndex: 10,
            padding: 20,
            justifyContent: 'space-between',
            flexDirection: 'column',
            boxShadow: ' 0 0 10px rgba(0,0,0,.25)'
          }
    };
  });

  const { classes } = useStyles();

  const [filters, setFilters] = useState<any>(null);
  const [selectedFilters, setSelectedFilters] = useState<any>(null);
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(!isMobile);

  useEffect(() => {
    if (!component) return;

    setFilters(generateFilterData(component, availableOptions));
  }, [component, availableOptions]);

  useEffect(() => {
    if (selectedFilters) {
      onSelectedFiltersChanged(selectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    setSelectedFilters(activeFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const onFilterChanged = (name: string, values: any[]) => {
    const f: any = { ...selectedFilters, [name]: values };

    if (!values.length) {
      delete f[name];
    }
    setSelectedFilters(f);
  };

  const renderMobileLink = () => {
    return (
      <Box display="flex" alignItems="start">
        {!isFiltersVisible && (
          <TextButton
            text={t('filterSelection')}
            onClick={() => setIsFiltersVisible(true)}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {filters && isMobile && renderMobileLink()}
      {filters && isFiltersVisible && (
        <Box
          className={classes.filterRow}
          style={{
            display: isFiltersVisible ? (isMobile ? 'flex' : 'block') : 'none'
          }}>
          {isMobile && (
            <>
              <Typography variant="body1" mb={2}>
                Filter:
              </Typography>
              {activeFilters && (
                <Box mb={1}>
                  <TextButton
                    text={t('resetFilter')}
                    onClick={() => {
                      resetActiveFilters && resetActiveFilters();
                      setIsFiltersVisible(false);
                    }}
                  />
                </Box>
              )}
            </>
          )}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            className={classes.stack}
            spacing={{ xs: 3 }}
            justifyContent={{ sm: 'space-evenly' }}
            marginY={{ md: 2 }}>
            {filters.map((filter: any) => {
              if (filter.filterValues && filter.filterValues.length === 1) {
                return;
              }

              return (
                <FilterSelect
                  filter={filter}
                  key={filter.identifier}
                  onFilterChanged={onFilterChanged}
                  activeFilters={activeFilters}
                />
              );
            })}
          </Stack>

          {isMobile && (
            <Box display="flex" alignItems={'end'} flex={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setIsFiltersVisible(false);
                }}>
                {t('applyFilter')}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default FilterRow;
