import { Button } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IComponentProps {
  text: string;
  onClick: () => void;
}

const TextButton: FC<IComponentProps> = ({ text, onClick }): ReactElement => {
  const useStyles = makeStyles()(() => {
    return {
      button: {
        height: '32px!important',
        textDecoration: 'underline !important',
        padding: '0!important',
        color: '#000!important',
        '&:hover': {
          background: 'none !important'
        },
        '& span': {
          display: 'none !important'
        }
      }
    };
  });

  const { classes } = useStyles();

  return (
    <Button variant="text" onClick={() => onClick()} className={classes.button}>
      {text}
    </Button>
  );
};

export default TextButton;
