import { createTheme } from '@mui/material/styles';
import {
  darkGray,
  white,
  blue,
  lightBlue,
  darkBlue,
  black,
  gray,
  light
} from '../src/Constants/Colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },

  palette: {
    primary: {
      light: lightBlue,
      main: blue,
      dark: darkBlue,
      contrastText: white
    }
  },
  typography: {
    fontFamily: 'Tahoma',
    h1: {
      fontSize: '26px',
      color: darkBlue
    },
    h2: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: darkGray
    },
    h3: {
      fontSize: '16px',
      color: darkGray
    },
    h4: {
      fontSize: '14px',
      color: darkGray
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          height: '48px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: gray,
          color: black
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: light
          }
        }
      }
    }
  }
});

export default theme;
