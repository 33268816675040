export const getParsedJSON = (data: string[]) => {
  return data.map((el: string) => {
    try {
      return JSON.parse(el);
    } catch (e) {
      console.error('parsing filter item failed', e);
      return {};
    }
  });
};
