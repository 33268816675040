import React, { FC, ReactElement } from 'react';
import DefaultComponent, { IComponentProps } from './DefaultComponent';
import { useAppSelector } from '../hooks';
import {
  IConfiguration,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import {
  findFirstSelectedOptionByComponentIdentifier,
  findSelectedOptionAmount
} from '../Utils/FindInConfiguration';
import { COMPONENT_CPU_IDENTIFIER } from '../Constants/Constants';

const RAMComponent: FC<IComponentProps> = ({
  component,
  hint,
  checkOptionDisabled,
  optionLabel,
  disableAmountSelection = false,
  allowZeroAmount,
  optionFilterFunction
}): ReactElement => {
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const selectedCPU: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_CPU_IDENTIFIER,
      selectedOptions,
      configuration
    );
  const selectedCPUAmount = findSelectedOptionAmount(
    selectedCPU,
    COMPONENT_CPU_IDENTIFIER,
    selectedOptions
  );
  const hasTwoCPUs = selectedCPUAmount == 2;
  const minAmount = hasTwoCPUs ? 2 : 1;

  const determineAmountFunction = (
    optionIdentifier: string,
    amount: number,
    selectedAmount: number
  ) => {
    if (!hasTwoCPUs) {
      return amount;
    }
    if (selectedAmount > amount) {
      return amount % 2 > 0 ? amount - 1 : amount;
    } else {
      return amount % 2 > 0 ? amount + 1 : amount;
    }
  };
  return (
    <DefaultComponent
      component={component}
      determineAmountFunction={determineAmountFunction}
      optionFilterFunction={optionFilterFunction}
      optionLabel={optionLabel}
      disableAmountSelection={disableAmountSelection}
      allowZeroAmount={allowZeroAmount}
      checkOptionDisabled={checkOptionDisabled}
      minAmount={minAmount}
      hint={hint}
    />
  );
};

export default RAMComponent;
