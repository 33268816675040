export const darkGray = '#3C3C3B';
export const lightgray = '#f3f3f3';
export const gray = '#dadada';
export const white = '#ffffff';
export const blue = '#0967A6';
export const lightBlue = '#698677';
export const darkBlue = '#133158';
export const red = '#ba000d';
export const black = '#263238';
export const light = '#e4ebf5';
export const disabledGray = '#00000042';
export const green = '#4FC36D';
export const errorRed = '#ED1C29';
