import React, {
  ReactElement,
  FC,
  SyntheticEvent,
  useMemo,
  useState
} from 'react';
import { makeStyles } from 'tss-react/mui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { t } from 'react-i18nify';
import { darkBlue, errorRed, white } from '../Constants/Colors';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Box,
  TextField,
  Typography,
  DialogActions
} from '@mui/material';
import { loadbyconfigurationcode } from '../Reducers/ConfigurationSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AbsoluteDialog } from './ContainerPositionProvider';
const useStyles = makeStyles()((theme) => {
  return {
    MuiDialogBorder: {
      borderTop: '7px solid ' + theme.palette.primary.main
    },
    dialogTitleRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '15px'
    },
    codeFieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: 24,
      marginBottom: 32,
      alignItems: 'center'
    },
    codeField: {
      fontSize: '16px !important',
      borderRadius: 4,
      height: 40,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        height: 56,
        width: '50%'
      }
    },
    activeButton: {
      padding: '6px 15px'
    },
    errorResult: {
      background: errorRed,
      color: white,
      padding: '20px',
      borderRadius: '4px',
      fontSize: 16,
      lineHeight: 1.7,
      textAlign: 'center',
      display: 'flex',
      marginTop: '30px',
      width: '70%'
    },
    errorResultIcon: {
      float: 'left',
      paddingRight: '20px'
    },
    '& MuiInputBase': {
      textAlign: 'center'
    }
  };
});

const LoadConfiguration: FC = (): ReactElement => {
  const [loadOpen, setLoadOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const [code, setCode] = useState(
    useAppSelector((state) => state.configuration.shareCode)
  );

  const handleLoadClickOpen = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoadOpen(true);
  };
  const handleClose = () => {
    setLoadOpen(false);
  };
  const { classes } = useStyles();

  const [errorMessage, setErrorMessage] = React.useState();
  const handleChange = (event: any) => {
    setCode(event.target.value);
  };

  const onLoadClick = useMemo(
    () => async () => {
      try {
        await dispatch(loadbyconfigurationcode(code as string));
        setLoadOpen(false);
      } catch (e: any) {
        setErrorMessage(e.message);
      }
    },
    [code, dispatch]
  );

  return (
    <>
      <Button onClick={handleLoadClickOpen} sx={{ color: darkBlue }}>
        <CloudUploadIcon sx={{ pr: 1 }} />
        <Typography>{t('load')}</Typography>
      </Button>
      <AbsoluteDialog
        classes={{
          paper: classes.MuiDialogBorder
        }}
        open={loadOpen}
        onClose={handleClose}>
        <Box className={classes.dialogTitleRoot}>
          <Typography variant="h1">{t('loadConfiguration')}</Typography>

          <CloseIcon onClick={handleClose} />
        </Box>
        <React.Fragment>
          <Typography sx={{ p: 2 }} variant="h4">
            {t('enterCode')}
          </Typography>
          <div className={classes.codeFieldWrapper}>
            <TextField
              className={classes.codeField}
              value={code}
              variant={'outlined'}
              inputProps={{
                style: { textAlign: 'center', fontSize: '15px' }
              }}
              onChange={handleChange}
              placeholder={t('codeInputPlaceholder')}
            />
            {errorMessage && (
              <div className={classes.errorResult}>
                <ErrorIcon className={classes.errorResultIcon} />
                <Typography variant="h4">{t('errormessage')}</Typography>
              </div>
            )}
          </div>
        </React.Fragment>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            onClick={onLoadClick}
            className={classes.activeButton}>
            {t('loadButton')}
          </Button>
        </DialogActions>
      </AbsoluteDialog>
    </>
  );
};

export default LoadConfiguration;
