import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import DefaultComponent from '../Components/DefaultComponent';
import {
  loadInitialConfiguration,
  saveConfigurationForCart,
  setErrorMessage
} from '../Reducers/ConfigurationSlice';
import {
  IConfiguration,
  IGroupedComponents,
  IHint,
  ISelectedOptions
} from '../Interface/Interface';
import ConfigurationInfo from '../Components/ConfigurationInfo';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import Select from '@mui/material/Select';
import { t } from 'react-i18nify';
import {
  CATEGORY_ATTRIBUTE_KEY_SOFTWARE,
  COMPONENT_CPU_COOLER_IDENTIFIER,
  COMPONENT_HARDDISK_IDENTIFIER,
  COMPONENT_POWER_ADAPTER_IDENTIFIER,
  COMPONENT_RAID_CONTROLLER_IDENTIFIER,
  COMPONENT_RAM_IDENTIFIER
} from '../Constants/Constants';
import HarddiskComponent from '../Components/HarddiskComponent';
import OperatingSystemComponent from '../Components/OperatingSystemComponent';
import PowerAdapterComponent from '../Components/PowerAdapterComponent';
import SnackBar from '../Components/SnackBar';
import RaidControllerComponent from '../Components/RaidControllerComponent';
import CPUCoolerComponent from '../Components/CPUCoolerComponent';
import PriceBox from '../Components/PriceBox';
import { darkBlue, lightgray, red, white } from '../Constants/Colors';
import MultiSelectComponent from '../Components/MultiSelectComponent';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import Progress from '../Components/Progress';
import createMessageInterface from '../Utils/createMessageInterface';
import { store } from '../store';
import ValidationErrorDialog from '../Components/ValidationErrorDialog';
import RAMComponent from '../Components/RAMComponent';
import { StickyGrid } from '../Components/StickyGrid';
import {
  AbsoluteDialog,
  useContainerSizeUpdate
} from '../Components/ContainerPositionProvider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ConfiguratorScreen = () => {
  const dispatch = useAppDispatch();
  const groupedComponents: IGroupedComponents | undefined = useAppSelector(
    (state) => state.configuration.groupedComponents
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const categoryTranslations: any = useAppSelector(
    (state) => state.configuration.categoryTranslations
  );
  const selectedOptions: ISelectedOptions | undefined =
    configuration?.selectedOptions;
  const switchOptionsError: string | undefined = useAppSelector(
    (state) => state.configuration.errorMessage
  );

  const hints: IHint[] | undefined = useAppSelector(
    (state) => state.configuration.hints
  );
  const hasHints: boolean = hints ? hints.length > 0 : false;

  const hasDeletedOptionsSelected: boolean = useAppSelector(
    (state) =>
      state.configuration.configuration?.hasDeletedOptionsSelected || false
  );

  useEffect(() => {
    if (hasDeletedOptionsSelected) {
      setErrorDialogOpen(true);
    }
  }, [hasDeletedOptionsSelected]);

  const useStyles = makeStyles()((theme) => {
    return {
      root: {
        margin: theme.spacing(1.5)
      },
      Components: {
        [theme.breakpoints.up('lg')]: {
          paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(18)
        },
        width: '100%'
      },
      itemTitle: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px !important',
          paddingRight: theme.spacing(2)
        }
      },
      ConfigurationInfo: {
        backgroundColor: lightgray,
        height: 'fit-content',
        maxWidth: 'fit-content',
        marginTop: theme.spacing(3),
        padding: theme.spacing(4)
      },
      buttongroup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'inherit',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row'
        }
      },
      footer: {
        backgroundColor: white,
        position: 'fixed',
        bottom: '0',
        minWidth: '100%',
        right: '0',
        left: '0',
        padding: theme.spacing(1)
      },
      dialogBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0, 2, 0, 0.75)
      },
      footerSummery: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end !important',
        padding: '0px !important'
      },
      footerSummeryCongigInfo: {
        textDecoration: 'underline',
        fontWeight: 'bold',
        pl: 1,
        color: darkBlue
      },
      footerButtongroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0, 2, 2, 2),
        width: 'calc(100% - 32px)'
      },
      dialogOverlay: {
        '& .MuiDialog-paper': {
          maxWidth: 'none !important',
          maxHeight: 'none !important',
          width: '100% !important',
          height: '100% !important',
          margin: '0 !important',
          overflowY: 'inherit',
          backgroundColor: lightgray
        }
      },
      select: {
        width: '50%',
        textTransform: 'uppercase',
        fontFamily: 'Tahoma !important',
        fontSize: '14px !important',
        color: `${darkBlue} !important`
      },
      menuItem: {
        textTransform: 'uppercase',
        fontFamily: 'Tahoma !important',
        fontSize: '14px !important',
        color: `${darkBlue} !important`
      }
    };
  });

  const { classes } = useStyles();

  const [activeTabValue, setActiveTabValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  useEffect(() => {
    dispatch(loadInitialConfiguration());
    initAdminMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setRootRef } = useContainerSizeUpdate(16, 16);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabValue(newValue);
  };

  const ondropdownChange = (event: any) => {
    setActiveTabValue(event.target.value);
  };

  const onNextStepClick = () => {
    setActiveTabValue((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };

  const onAddToCartClick = async () => {
    if (
      !hasDeletedOptionsSelected &&
      !hasHints &&
      configuration &&
      selectedOptions
    ) {
      let configurationCode;
      const query = new URLSearchParams(location.search);
      if (query.has('_frombasket') && query.get('_frombasket') === '1') {
        configurationCode = configuration.code;
      }
      dispatch(
        saveConfigurationForCart(
          configuration.item.identifier,
          selectedOptions,
          configurationCode
        )
      );
    } else {
      setErrorDialogOpen(true);
    }
  };

  const initAdminMode = () => {
    const query = new URLSearchParams(location.search);
    if (query.has('_adminmode')) {
      const messageInterface = createMessageInterface();
      messageInterface.addListener('getConfiguration', handleAdminSave);
    }
  };

  const handleAdminSave = async (event: any) => {
    const query = new URLSearchParams(location.search);
    const adminMode = query.get('_adminmode');
    // @ts-ignore
    const currentConfiguration: IConfiguration =
      store.getState().configuration.configuration;
    const configuration = {
      code: currentConfiguration.code,
      itemIdentifier: currentConfiguration.item.identifier,
      configurationType: 'defaultoptions',
      selectedOptions: currentConfiguration.selectedOptions
    };
    // @ts-ignore
    event.source.postMessage(
      { ...event.data, adminMode, configuration },
      event.origin
    );
  };

  const maxStep: any =
    groupedComponents && Object.keys(groupedComponents).length - 1;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Box ref={setRootRef} className={classes.root}>
      <Progress />
      <Box sx={{ display: 'flex' }}>
        <Typography className={classes.itemTitle} variant="h1">
          {configuration?.item.title}
        </Typography>
        {isMobile && (
          <Select
            className={classes.select}
            value={activeTabValue}
            onChange={ondropdownChange}>
            {groupedComponents &&
              Object.keys(groupedComponents).map(
                (groupKey: string, i: number) => (
                  <MenuItem
                    className={classes.menuItem}
                    value={i}
                    key={groupKey}>
                    {categoryTranslations[groupKey]}{' '}
                  </MenuItem>
                )
              )}
          </Select>
        )}
      </Box>

      {!isMobile && (
        <Tabs
          value={activeTabValue}
          onChange={onTabChange}
          aria-label="component group tabs">
          {groupedComponents &&
            Object.keys(groupedComponents).map((groupKey: string) => (
              <Tab
                sx={{ background: lightgray }}
                label={t(`componentGroupTabs.${groupKey}`)}
                key={groupKey}
              />
            ))}
        </Tabs>
      )}

      <Grid container>
        <Grid item={true} lg={8} md={12} sm={12} className={classes.Components}>
          {groupedComponents &&
            Object.keys(groupedComponents).map((groupKey: string, i) => (
              <TabPanel index={i} key={groupKey} value={activeTabValue}>
                {groupKey === CATEGORY_ATTRIBUTE_KEY_SOFTWARE && (
                  <OperatingSystemComponent />
                )}
                {groupedComponents[groupKey].map((component, i) => {
                  if (component.hiddenInFrontend) {
                    return null;
                  } else if (
                    component.identifier ===
                    COMPONENT_RAID_CONTROLLER_IDENTIFIER
                  ) {
                    return (
                      <RaidControllerComponent key={i} component={component} />
                    );
                  } else if (
                    component.identifier === COMPONENT_CPU_COOLER_IDENTIFIER
                  ) {
                    return <CPUCoolerComponent key={i} component={component} />;
                  } else if (
                    component.identifier === COMPONENT_POWER_ADAPTER_IDENTIFIER
                  ) {
                    return (
                      <PowerAdapterComponent key={i} component={component} />
                    );
                  } else if (
                    component.identifier === COMPONENT_HARDDISK_IDENTIFIER
                  ) {
                    return <HarddiskComponent key={i} />;
                  } else if (
                    component.identifier === COMPONENT_RAM_IDENTIFIER
                  ) {
                    return (
                      <RAMComponent
                        key={i}
                        component={component}
                        allowZeroAmount={component.isMultiselect}
                      />
                    );
                  } else if (component.isMultiselect) {
                    return (
                      <MultiSelectComponent key={i} component={component} />
                    );
                  } else {
                    return (
                      <DefaultComponent
                        key={i}
                        component={component}
                        allowZeroAmount={component.isMultiselect}
                      />
                    );
                  }
                })}
              </TabPanel>
            ))}
        </Grid>

        {configuration && !isMobile && (
          <StickyGrid
            bottomMargin={16}
            item={true}
            lg={4}
            md={12}
            sm={12}
            className={classes.ConfigurationInfo}
            sx={{
              marginTop: '24px',
              position: 'sticky',
              right: 0
            }}>
            <ConfigurationInfo />
            <Box>
              <PriceBox />
              <ButtonGroup className={classes.buttongroup}>
                {activeTabValue === maxStep ? (
                  <Button
                    variant="contained"
                    onClick={onAddToCartClick}
                    sx={{ backgroundColor: darkBlue, mt: 2 }}>
                    {t('addToCart')}
                  </Button>
                ) : (
                  <Button variant="contained" onClick={onNextStepClick}>
                    {t('nextStep')}
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          </StickyGrid>
        )}
        {configuration && isMobile && (
          <Grid sx={{ boxShadow: 5 }} className={classes.footer}>
            <Box className={classes.dialogBox}>
              <Button
                variant="text"
                className={classes.footerSummery}
                onClick={handleClickOpen}>
                <Box sx={{ display: 'flex' }}>
                  <ArrowRightIcon sx={{ color: darkBlue }} />
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      color: darkBlue,
                      fontSize: '14px'
                    }}>
                    {t('configurationHeadline')}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: red,
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>
                  {t('verify')}
                </Typography>
              </Button>
              <PriceBox />

              <AbsoluteDialog
                open={open}
                onClose={handleClose}
                className={classes.dialogOverlay}>
                <DialogActions sx={{ padding: '0px' }}>
                  <IconButton sx={{ color: 'black' }} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
                <ConfigurationInfo />
              </AbsoluteDialog>
            </Box>

            <ButtonGroup
              sx={{
                justifyContent:
                  activeTabValue === maxStep ? 'end' : 'space-between'
              }}
              className={classes.footerButtongroup}>
              {activeTabValue === maxStep ? (
                ''
              ) : (
                <Button
                  variant="contained"
                  onClick={onNextStepClick}
                  sx={{ width: 'calc(50% - 12px)' }}>
                  {t('nextStep')}
                </Button>
              )}
              <Button
                variant="contained"
                onClick={onAddToCartClick}
                sx={{ backgroundColor: darkBlue, width: 'calc(50% - 12px)' }}>
                {t('addToCart')}
              </Button>
            </ButtonGroup>
          </Grid>
        )}
        {errorDialogOpen && (
          <ValidationErrorDialog
            title={
              hasDeletedOptionsSelected
                ? 'deletedOptionsSelectedTitle'
                : undefined
            }
            errorMessage={
              hasDeletedOptionsSelected
                ? 'deletedOptionsSelectedErrorMessage'
                : undefined
            }
            open={errorDialogOpen}
            handleClose={() => setErrorDialogOpen(false)}
          />
        )}
        <SnackBar
          message={switchOptionsError}
          onCloseMessage={() => dispatch(setErrorMessage(undefined))}
        />
      </Grid>
    </Box>
  );
};

export default ConfiguratorScreen;
