import {
  Box,
  Checkbox,
  Grid,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { debounce } from 'lodash';
import React, { FC, ReactElement, useEffect } from 'react';
import { t } from 'react-i18nify';
import { makeStyles } from 'tss-react/mui';
import { lightgray } from '../Constants/Colors';
import {
  ATTRIBUTE_KEY_BUS_TYPE,
  ATTRIBUTE_KEY_COMPONENT_FILTERS,
  ATTRIBUTE_KEY_RAID_LEVEL,
  ATTRIBUTE_VALUE_SAS,
  COMPONENT_HARDDISK_IDENTIFIER,
  COMPONENT_RAID_CONTROLLER_IDENTIFIER,
  COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
  COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
  OPTION_NO_RAID_CONTROLLER_IDENTIFIER,
  OPTION_NO_RAID_HDD_IDENTIFIER,
  OPTION_NO_RAID_SSD_IDENTIFIER,
  OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER,
  ATTRIBUTE_PRODUCT_TYPE,
  PRODUCT_TYPE_WORKSTATION,
  COMPONENT_MAINBOARD_IDENTIFIER,
  ATTRIBUTE_KEY_ONBOARD_RAID_CONTROLLER,
  ATTRIBUTE_KEY_SLOTS_NVME_25,
  ATTRIBUTE_KEY_SLOTS_NVME_35,
  COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
  OPTION_NO_RAID_NVME_IDENTIFIER,
  ATTRIBUTE_VALUE_SSD,
  ATTRIBUTE_VALUE_HDD,
  ATTRIBUTE_VALUE_M2,
  ATTRIBUTE_VALUE_NVME
} from '../Constants/Constants';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  IAttribute,
  IComponent,
  IConfiguration,
  IHarddiskGroupComponent,
  IItem,
  IOption,
  IRaidLevelOption,
  ISelectedOptions
} from '../Interface/Interface';
import { switchOptions } from '../Reducers/ConfigurationSlice';
import { filterOptions } from '../Utils/filterUtils';
import {
  findFirstSelectedOptionByComponentIdentifier,
  findSelectedOptionAmount,
  findUsernote,
  getAttribute,
  getAttributeValue
} from '../Utils/FindInConfiguration';
import { isSmartPhoneSize } from '../Utils/isMobile';
import AmountSelect from './AmountSelect';
import DefaultOption from './DefaultOption';
import FilterRow from './FilterRow';
import TextButton from './TextButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: 'inherit' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const HarddiskComponent: FC = (): ReactElement => {
  const isMobile = isSmartPhoneSize();
  const [isFilterAvailable, setIsFilterAvailable] = React.useState(false);
  const [driveComponent, setDriveComponent] = React.useState<IComponent | null>(
    null
  );
  const [ssdOptions, setSsdOption] = React.useState<IOption[]>([]);
  const [filteredSsdOptions, setFilteredSsdOptions] =
    React.useState<IOption[]>(ssdOptions);
  const [hddOptions, setHddOptions] = React.useState<IOption[]>([]);
  const [filteredHddOptions, setFilteredHddOptions] =
    React.useState<IOption[]>(hddOptions);

  const [m2Options, setM2Options] = React.useState<IOption[]>([]);
  const [filteredM2Options, setFilteredM2Options] =
    React.useState<IOption[]>(m2Options);
  const [nvmeOptions, setNvmeOptions] = React.useState<IOption[]>([]);

  const [activeSsdFilters, setActiveSsdFilters] = React.useState<any>(null);
  const [activeM2Filters, setActiveM2Filters] = React.useState<any>(null);
  const [activehddFilters, setActiveHddFilters] = React.useState<any>(null);

  const tabMap: { [key: string]: IOption[] } = {
    [ATTRIBUTE_VALUE_HDD]: hddOptions,
    [ATTRIBUTE_VALUE_SSD]: ssdOptions,
    [ATTRIBUTE_VALUE_M2]: m2Options
  };

  const dispatch = useAppDispatch();

  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );

  const hasNvmeSlots = () => {
    const slots25 = getAttribute(
      configuration?.item,
      ATTRIBUTE_KEY_SLOTS_NVME_25
    );
    const slots35 = getAttribute(
      configuration?.item,
      ATTRIBUTE_KEY_SLOTS_NVME_35
    );
    return slots25 || slots35;
  };

  if (hasNvmeSlots()) {
    tabMap[ATTRIBUTE_VALUE_NVME] = nvmeOptions;
  }

  // @ts-ignore
  const tabs = Object.keys(tabMap).filter(
    (tab) => tabMap[tab] && tabMap[tab].length > 0
  );

  const [activeTabIndex, setActiveTabIndex] = React.useState(tabs[0]);

  useEffect(() => {
    if (!activeTabIndex) {
      setActiveTabIndex(tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTabIndex(newValue);
  };
  const useStyles = makeStyles()((theme) => {
    return {
      grid: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: lightgray,
        padding: `${theme.spacing(3.125, 4.375, 2.5, 4.375)}`,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(3.125, 1.5, 2.5, 1.5)}`
        }
      },
      tabpanel: {
        display: 'flex',
        flexDirection: 'column'
      },
      raidLevel: {
        fontWeight: 'bold !important',
        paddingTop: '15px'
      }
    };
  });

  const { classes } = useStyles();

  const allComponents: IComponent[] | undefined = useAppSelector(
    (state) => state.configuration.configuration?.components
  );
  const amountHDDsSelected = useAppSelector(
    (state) =>
      state.configuration.harddiskComponent?.harddisk?.numSelectedHarddisks
  ) as number;
  const amountSSDsSelected = useAppSelector(
    (state) => state.configuration.harddiskComponent?.harddisk?.numSelectedSSDs
  ) as number;
  const amountNvmesSelected = useAppSelector(
    (state) => state.configuration.harddiskComponent?.harddisk?.numSelectedNvmes
  ) as number;
  const groupComponent: IHarddiskGroupComponent | undefined = useAppSelector(
    (state) => state.configuration.harddiskComponent
  );
  const itemIdentifier: string | undefined = useAppSelector(
    (state) => state.configuration.configuration?.item.identifier
  );
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );

  const selectedHDDOptionIdentifierArray: string[] | undefined =
    selectedOptions?.[COMPONENT_RAID_LEVEL_HDD_IDENTIFIER]?.map(
      (optionIdentifier) => optionIdentifier.identifier
    );

  const selectedSSDOptionIdentifierArray: string[] | undefined =
    selectedOptions?.[COMPONENT_RAID_LEVEL_SSD_IDENTIFIER]?.map(
      (optionIdentifier) => optionIdentifier.identifier
    );

  const selectedNvmeOptionIdentifierArray: string[] | undefined =
    selectedOptions?.[COMPONENT_RAID_LEVEL_NVME_IDENTIFIER]?.map(
      (optionIdentifier) => optionIdentifier.identifier
    );

  const allowedRaidLevels = useAppSelector(
    (state) => state.configuration.allowedRaidLevels
  );

  useEffect(() => {
    if (!groupComponent) return;

    if (groupComponent.harddisk?.ssds) {
      setSsdOption(groupComponent.harddisk.ssds);
      setFilteredSsdOptions(groupComponent.harddisk.ssds);
    }

    if (groupComponent.harddisk?.harddisks) {
      setHddOptions(groupComponent.harddisk.harddisks);
      setFilteredHddOptions(groupComponent.harddisk.harddisks);
    }
    if (groupComponent.harddisk?.m2) {
      setM2Options(groupComponent.harddisk.m2);
      setFilteredM2Options(groupComponent.harddisk.m2);
    }
    if (groupComponent.harddisk?.nvmes) {
      setNvmeOptions(groupComponent.harddisk.nvmes);
    }
  }, [groupComponent]);

  useEffect(() => {
    if (
      !isFilterAvailable ||
      (!activehddFilters && !activeSsdFilters && !activeM2Filters)
    )
      return;
    const driveOptions = tabMap[activeTabIndex];
    let activeFilters = activehddFilters;
    if (activeTabIndex === ATTRIBUTE_VALUE_HDD) {
      activeFilters = activehddFilters;
    } else if (activeTabIndex === ATTRIBUTE_VALUE_SSD) {
      activeFilters = activeSsdFilters;
    } else if (activeTabIndex === ATTRIBUTE_VALUE_M2) {
      activeFilters = activeM2Filters;
    }

    const filtered = filterOptions(driveOptions, activeFilters);

    if (activeTabIndex === ATTRIBUTE_VALUE_HDD) {
      setFilteredHddOptions(filtered);
    } else if (activeTabIndex === ATTRIBUTE_VALUE_SSD) {
      setFilteredSsdOptions(filtered);
    } else if (activeTabIndex === ATTRIBUTE_VALUE_M2) {
      setFilteredM2Options(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSsdFilters,
    activehddFilters,
    activeM2Filters,
    isFilterAvailable,
    activeTabIndex,
    ssdOptions,
    hddOptions,
    m2Options
  ]);

  const resetToDefault = () => {
    if (activeTabIndex === ATTRIBUTE_VALUE_HDD) {
      setActiveHddFilters(null);
      setFilteredHddOptions(hddOptions);
    } else if (activeTabIndex === ATTRIBUTE_VALUE_SSD) {
      setActiveSsdFilters(null);
      setFilteredSsdOptions(ssdOptions);
    } else if (activeTabIndex === ATTRIBUTE_VALUE_M2) {
      setActiveM2Filters(null);
      setFilteredM2Options(m2Options);
    }
  };

  useEffect(() => {
    if (allComponents) {
      const component = allComponents.filter(
        (cmp) => cmp.identifier === COMPONENT_HARDDISK_IDENTIFIER
      )[0];

      if (component) {
        setDriveComponent(component);
        const componentFilterAttribute: IAttribute | undefined = getAttribute(
          component,
          ATTRIBUTE_KEY_COMPONENT_FILTERS
        );

        if (componentFilterAttribute) setIsFilterAvailable(true);
      }
    }
  }, [allComponents]);

  const harddiskOptionChange = (optionIdentifier: string) => {
    // @ts-ignore
    dispatch(
      switchOptions(
        COMPONENT_HARDDISK_IDENTIFIER,
        optionIdentifier as string,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions
      )
    );
  };

  const harddiskOptionAmountChange = (
    optionIdentifier: string,
    amount: number
  ) => {
    dispatch(
      switchOptions(
        COMPONENT_HARDDISK_IDENTIFIER,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions,
        amount
      )
    );
  };

  const onUserNoteChange = debounce(
    (
      componentIdentifier: string,
      optionIdentifier: string,
      userNote: string,
      amount: number
    ) => {
      dispatch(
        switchOptions(
          componentIdentifier,
          optionIdentifier,
          itemIdentifier as string,
          selectedOptions as ISelectedOptions,
          amount,
          userNote
        )
      );
    },
    400
  );

  const raidLevelHarddiskOptionChange = (optionIdentifier: string) => {
    // @ts-ignore
    dispatch(
      switchOptions(
        COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
        optionIdentifier as string,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions
      )
    );
  };

  const raidLevelSSDOptionChange = (optionIdentifier: string) => {
    // @ts-ignore
    dispatch(
      switchOptions(
        COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
        optionIdentifier as string,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions
      )
    );
  };

  const raidLevelNvmeOptionChange = (optionIdentifier: string) => {
    // @ts-ignore
    dispatch(
      switchOptions(
        COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
        optionIdentifier as string,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions
      )
    );
  };

  const raidLevelOptionAmountChange = (
    raidLevelComponent: string,
    optionIdentifier: string,
    amount: number
  ) => {
    dispatch(
      switchOptions(
        raidLevelComponent,
        optionIdentifier,
        itemIdentifier as string,
        selectedOptions as ISelectedOptions,
        amount
      )
    );
  };

  const selectedRaidController =
    selectedOptions?.[COMPONENT_RAID_CONTROLLER_IDENTIFIER]?.[0].identifier;

  const selectedHddRaidLevel =
    selectedOptions?.[COMPONENT_RAID_LEVEL_HDD_IDENTIFIER]?.[0].identifier;
  const selectedSsdRaidLevel =
    selectedOptions?.[COMPONENT_RAID_LEVEL_SSD_IDENTIFIER]?.[0].identifier;

  const selectedNvmeRaidLevel =
    selectedOptions?.[COMPONENT_RAID_LEVEL_NVME_IDENTIFIER]?.[0].identifier;

  const isRaidLevelDisabled = (
    option: IRaidLevelOption,
    amountDrivesSelected: number
  ) => {
    let raidLevelAvailableForOnboardController = true;
    let raidLevelAllowed = true;
    const raidLevelAttribute: IAttribute | undefined = option.attributes.find(
      (attribute) => ATTRIBUTE_KEY_RAID_LEVEL === attribute.identifier
    );

    if (selectedRaidController && allowedRaidLevels[selectedRaidController]) {
      raidLevelAllowed =
        allowedRaidLevels[selectedRaidController].includes(option.identifier) ||
        option.identifier === OPTION_NO_RAID_HDD_IDENTIFIER ||
        option.identifier === OPTION_NO_RAID_SSD_IDENTIFIER ||
        option.identifier === OPTION_NO_RAID_NVME_IDENTIFIER;
    }
    if (selectedRaidController === OPTION_NO_RAID_CONTROLLER_IDENTIFIER) {
      raidLevelAvailableForOnboardController =
        option.identifier === OPTION_NO_RAID_HDD_IDENTIFIER ||
        option.identifier === OPTION_NO_RAID_SSD_IDENTIFIER ||
        option.identifier === OPTION_NO_RAID_NVME_IDENTIFIER;
    }
    if (
      selectedRaidController === OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER &&
      raidLevelAttribute &&
      raidLevelAttribute.values[0]
    ) {
      raidLevelAvailableForOnboardController =
        parseInt(raidLevelAttribute.values[0].value) <= 1;
    }
    return (
      amountDrivesSelected < option.minimumDrivesRequired ||
      !raidLevelAvailableForOnboardController ||
      !raidLevelAllowed
    );
  };

  const isHarddiskDisabled = (option: IOption) => {
    if (selectedRaidController !== OPTION_NO_RAID_CONTROLLER_IDENTIFIER) {
      return false;
    }

    const busTypeAttribute: IAttribute | undefined = option.attributes.find(
      (attribute) => ATTRIBUTE_KEY_BUS_TYPE === attribute.identifier
    );
    return (
      busTypeAttribute &&
      busTypeAttribute.values[0] &&
      busTypeAttribute.values[0].value === ATTRIBUTE_VALUE_SAS
    );
  };

  const selectedMainboard: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_MAINBOARD_IDENTIFIER,
      selectedOptions,
      configuration
    );
  let mainboardHasOnboardRaid = false;
  if (selectedMainboard) {
    const onboardRaidAttribute: IAttribute | undefined =
      selectedMainboard.attributes.find(
        (attribute) =>
          ATTRIBUTE_KEY_ONBOARD_RAID_CONTROLLER === attribute.identifier
      );
    if (onboardRaidAttribute && onboardRaidAttribute.values[0]) {
      mainboardHasOnboardRaid = Boolean(onboardRaidAttribute.values[0].value);
    }
  }
  const item: IItem | undefined = useAppSelector(
    (state) => state.configuration.configuration?.item
  );
  const productType = getAttributeValue(item, ATTRIBUTE_PRODUCT_TYPE) as string;

  const showRaidLevelOptions = !(
    !mainboardHasOnboardRaid && productType === PRODUCT_TYPE_WORKSTATION
  );

  const renderFilter = () => {
    const activeFilters =
      activeTabIndex === ATTRIBUTE_VALUE_HDD
        ? activehddFilters
        : activeTabIndex === ATTRIBUTE_VALUE_SSD
        ? activeSsdFilters
        : activeM2Filters;
    const hasMultipleDriveOption = tabMap[activeTabIndex].length > 0;
    const renderFilters =
      isFilterAvailable && driveComponent && hasMultipleDriveOption;
    const renderHDDFilters =
      renderFilters && activeTabIndex === ATTRIBUTE_VALUE_HDD;
    const renderSSDFilters =
      renderFilters && activeTabIndex === ATTRIBUTE_VALUE_SSD;
    const renderM2Filters =
      renderFilters && activeTabIndex === ATTRIBUTE_VALUE_M2;
    const renderFilterResultEmpty =
      (renderHDDFilters && filteredHddOptions.length === 0) ||
      (renderSSDFilters && filteredSsdOptions.length === 0) ||
      (renderM2Filters && filteredM2Options.length === 0);
    return (
      <>
        {activeFilters && !isMobile && (
          <Box display="flex" alignItems="center">
            <TextButton
              text={t('resetFilter')}
              onClick={() => resetToDefault()}
            />
          </Box>
        )}
        {renderHDDFilters && (
          <FilterRow
            component={driveComponent}
            onSelectedFiltersChanged={(filters: any) =>
              setActiveHddFilters(filters)
            }
            activeFilters={activeFilters}
            resetActiveFilters={() => resetToDefault()}
            availableOptions={hddOptions}
          />
        )}
        {renderSSDFilters && (
          <FilterRow
            component={driveComponent}
            onSelectedFiltersChanged={(filters: any) =>
              setActiveSsdFilters(filters)
            }
            activeFilters={activeFilters}
            resetActiveFilters={() => resetToDefault()}
            availableOptions={ssdOptions}
          />
        )}
        {renderM2Filters && (
          <FilterRow
            component={driveComponent}
            onSelectedFiltersChanged={(filters: any) =>
              setActiveM2Filters(filters)
            }
            activeFilters={activeFilters}
            resetActiveFilters={() => resetToDefault()}
            availableOptions={m2Options}
          />
        )}
        {renderFilterResultEmpty && (
          <Typography variant="body1" color={'red'}>
            {t('noFilterResult')}
          </Typography>
        )}
      </>
    );
  };

  const renderSsdContent = () => (
    <div className={classes.tabpanel}>
      {renderFilter()}

      {filteredSsdOptions &&
        filteredSsdOptions.map((option, i) => (
          <DefaultOption
            key={i}
            option={option}
            onChange={harddiskOptionChange}
            control={
              <AmountSelect
                option={option}
                onAmountChanged={harddiskOptionAmountChange}
                amount={findSelectedOptionAmount(
                  option,
                  COMPONENT_HARDDISK_IDENTIFIER,
                  selectedOptions
                )}
                disabled={isHarddiskDisabled(option)}
              />
            }
          />
        ))}
      {showRaidLevelOptions && (
        <RadioGroup value={selectedSsdRaidLevel}>
          <Typography variant="h3" className={classes.raidLevel}>
            {groupComponent?.raidLevelSSD?.title}
          </Typography>
          {groupComponent?.raidLevelSSD?.options.map((option, i) => (
            <React.Fragment key={i}>
              <DefaultOption
                option={option}
                onChange={raidLevelSSDOptionChange}
                disabled={isRaidLevelDisabled(option, amountSSDsSelected)}
                control={
                  <Checkbox
                    checked={
                      selectedSSDOptionIdentifierArray
                        ? selectedSSDOptionIdentifierArray?.includes(
                            option.identifier
                          )
                        : false
                    }
                    onClick={(e: any) => {
                      const amountSSD = findSelectedOptionAmount(
                        option,
                        COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
                        selectedOptions
                      );
                      if (e.target.checked === false) {
                        raidLevelOptionAmountChange(
                          COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
                          option.identifier,
                          amountSSD - 1
                        );
                      }
                    }}
                    sx={{
                      padding: '9px 0px 9px 0px',
                      pointerEvents: 'auto'
                    }}
                  />
                }
              />
              {!selectedSSDOptionIdentifierArray?.includes(
                OPTION_NO_RAID_SSD_IDENTIFIER
              ) &&
                selectedSSDOptionIdentifierArray?.includes(
                  option.identifier
                ) && (
                  <>
                    <Typography>{t('remark')}</Typography>
                    <TextField
                      inputProps={{ maxLength: 280 }}
                      multiline
                      sx={{ pointerEvents: 'auto' }}
                      defaultValue={findUsernote(
                        option,
                        COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
                        selectedOptions
                      )}
                      onChange={(e: any) => {
                        const userNoteValue: string = e.target.value;
                        onUserNoteChange(
                          COMPONENT_RAID_LEVEL_SSD_IDENTIFIER,
                          option.identifier,
                          userNoteValue,
                          amountSSDsSelected
                        );
                      }}
                    />
                  </>
                )}
            </React.Fragment>
          ))}
        </RadioGroup>
      )}
    </div>
  );

  const renderHddContent = () => (
    <div className={classes.tabpanel}>
      {renderFilter()}

      {filteredHddOptions &&
        filteredHddOptions.map((option, i) => {
          const isDisabled = isHarddiskDisabled(option);
          return (
            <DefaultOption
              key={i}
              option={option}
              onChange={harddiskOptionChange}
              disabled={isDisabled}
              control={
                <AmountSelect
                  option={option}
                  onAmountChanged={harddiskOptionAmountChange}
                  amount={findSelectedOptionAmount(
                    option,
                    COMPONENT_HARDDISK_IDENTIFIER,
                    selectedOptions
                  )}
                  disabled={isDisabled}
                />
              }
            />
          );
        })}
      {showRaidLevelOptions && (
        <RadioGroup value={selectedHddRaidLevel}>
          <Typography variant="h3" className={classes.raidLevel}>
            {groupComponent?.raidLevelHarddisk?.title}
          </Typography>
          {groupComponent?.raidLevelHarddisk?.options.map((option, i) => (
            <React.Fragment key={i}>
              <DefaultOption
                option={option}
                onChange={raidLevelHarddiskOptionChange}
                disabled={isRaidLevelDisabled(option, amountHDDsSelected)}
                control={
                  <Checkbox
                    checked={
                      selectedHDDOptionIdentifierArray
                        ? selectedHDDOptionIdentifierArray?.includes(
                            option.identifier
                          )
                        : false
                    }
                    onClick={(e: any) => {
                      const amountHDD = findSelectedOptionAmount(
                        option,
                        COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
                        selectedOptions
                      );
                      if (e.target.checked === false) {
                        raidLevelOptionAmountChange(
                          COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
                          option.identifier,
                          amountHDD - 1
                        );
                      }
                    }}
                    sx={{
                      padding: '9px 0px 9px 0px',
                      pointerEvents: 'auto'
                    }}
                  />
                }
              />
              {!selectedHDDOptionIdentifierArray?.includes(
                OPTION_NO_RAID_HDD_IDENTIFIER
              ) &&
                selectedHDDOptionIdentifierArray?.includes(
                  option.identifier
                ) && (
                  <>
                    <Typography>{t('remark')}</Typography>
                    <TextField
                      inputProps={{ maxLength: 280 }}
                      multiline
                      sx={{ pointerEvents: 'auto' }}
                      defaultValue={findUsernote(
                        option,
                        COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
                        selectedOptions
                      )}
                      onChange={(e: any) => {
                        const userNoteValue = e.target.value;
                        onUserNoteChange(
                          COMPONENT_RAID_LEVEL_HDD_IDENTIFIER,
                          option.identifier,
                          userNoteValue,
                          amountHDDsSelected
                        );
                      }}
                    />
                  </>
                )}
            </React.Fragment>
          ))}
        </RadioGroup>
      )}
    </div>
  );

  const renderNvmeContent = () => (
    <div className={classes.tabpanel}>
      {nvmeOptions.map((option, i) => {
        const isDisabled =
          isHarddiskDisabled(option) ||
          !selectedRaidController ||
          selectedRaidController === OPTION_ONBOARD_RAID_CONTROLLER_IDENTIFIER;
        return (
          <DefaultOption
            key={i}
            option={option}
            onChange={harddiskOptionChange}
            disabled={isDisabled}
            control={
              <AmountSelect
                option={option}
                onAmountChanged={harddiskOptionAmountChange}
                amount={findSelectedOptionAmount(
                  option,
                  COMPONENT_HARDDISK_IDENTIFIER,
                  selectedOptions
                )}
                disabled={isDisabled}
              />
            }
          />
        );
      })}
      {showRaidLevelOptions && (
        <RadioGroup value={selectedNvmeRaidLevel}>
          <Typography variant="h3" className={classes.raidLevel}>
            {groupComponent?.raidLevelNvme?.title}
          </Typography>
          {groupComponent?.raidLevelNvme?.options.map((option, i) => (
            <React.Fragment key={i}>
              <DefaultOption
                option={option}
                onChange={raidLevelNvmeOptionChange}
                disabled={isRaidLevelDisabled(option, amountNvmesSelected)}
                control={
                  <Checkbox
                    checked={
                      selectedNvmeOptionIdentifierArray
                        ? selectedNvmeOptionIdentifierArray?.includes(
                            option.identifier
                          )
                        : false
                    }
                    onClick={(e: any) => {
                      const amountNvme = findSelectedOptionAmount(
                        option,
                        COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
                        selectedOptions
                      );
                      if (e.target.checked === false) {
                        raidLevelOptionAmountChange(
                          COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
                          option.identifier,
                          amountNvme - 1
                        );
                      }
                    }}
                    sx={{
                      padding: '9px 0px 9px 0px',
                      pointerEvents: 'auto'
                    }}
                  />
                }
              />
              {!selectedNvmeOptionIdentifierArray?.includes(
                OPTION_NO_RAID_NVME_IDENTIFIER
              ) &&
                selectedNvmeOptionIdentifierArray?.includes(
                  option.identifier
                ) && (
                  <>
                    <Typography>{t('remark')}</Typography>
                    <TextField
                      inputProps={{ maxLength: 280 }}
                      multiline
                      sx={{ pointerEvents: 'auto' }}
                      defaultValue={findUsernote(
                        option,
                        COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
                        selectedOptions
                      )}
                      onChange={(e: any) => {
                        const userNoteValue = e.target.value;
                        onUserNoteChange(
                          COMPONENT_RAID_LEVEL_NVME_IDENTIFIER,
                          option.identifier,
                          userNoteValue,
                          amountNvmesSelected
                        );
                      }}
                    />
                  </>
                )}
            </React.Fragment>
          ))}
        </RadioGroup>
      )}
    </div>
  );

  const renderM2Content = () => (
    <div className={classes.tabpanel}>
      {renderFilter()}
      {filteredM2Options &&
        filteredM2Options.map((option, i) => (
          <DefaultOption
            key={i}
            option={option}
            onChange={harddiskOptionChange}
            control={
              <AmountSelect
                option={option}
                onAmountChanged={harddiskOptionAmountChange}
                amount={findSelectedOptionAmount(
                  option,
                  COMPONENT_HARDDISK_IDENTIFIER,
                  selectedOptions
                )}
              />
            }
          />
        ))}
    </div>
  );

  const renderTabContent = (tab: string) => {
    switch (tab) {
      case 'hdd':
        return renderHddContent();
      case 'ssd':
        return renderSsdContent();
      case 'm2':
        return renderM2Content();
      case 'nvme':
        return renderNvmeContent();
      default:
        return null;
    }
  };

  return (
    <Grid className={classes.grid}>
      {activeTabIndex && (
        <Box sx={{ borderBottom: 'none', borderColor: 'divider', flexGrow: 1 }}>
          <>
            {allComponents?.map((component: IComponent, i: number) => {
              if (component.identifier === COMPONENT_HARDDISK_IDENTIFIER) {
                return (
                  <Typography key={i} variant="h2">
                    {component.title}
                  </Typography>
                );
              } else {
                return;
              }
            })}
          </>

          <Tabs
            sx={{ p: '15px 0px' }}
            value={activeTabIndex}
            onChange={handleTabChange}
            aria-label="drive tabs">
            {tabs.map((tab) => (
              <Tab label={t(tab)} key={tab} value={tab} />
            ))}
          </Tabs>
          <Grid container sx={{ pl: 2 }}>
            {tabs.map((tab, i) => (
              <TabPanel
                key={`tabpanel-${i}`}
                value={activeTabIndex}
                index={tab}>
                {renderTabContent(tab)}
              </TabPanel>
            ))}
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default HarddiskComponent;
