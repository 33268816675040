import { Typography } from '@mui/material';
import React, { ReactElement, FC } from 'react';
import { IConfiguration } from '../Interface/Interface';
import { useAppSelector } from '../hooks';
import { t } from 'react-i18nify';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'end'
      }
    },
    total: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };
});

const PriceBox: FC = (): ReactElement => {
  const { classes } = useStyles();

  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  let price = '';
  let priceHint = '';
  if (configuration) {
    const query = new URLSearchParams(location.search);
    if (
      query.has('_display_net_prices') &&
      query.get('_display_net_prices') === '1'
    ) {
      price = configuration.calculation.netTotalFormatted;
      priceHint = t('netPriceHint');
    } else {
      price = configuration.calculation.totalFormatted;
      priceHint = t('priceHint');
    }
  }

  return (
    <div className={classes.container}>
      <Typography
        sx={{ pb: 2, pt: 2, fontWeight: 'bold' }}
        variant="h3"
        className={classes.total}>
        {t('total')}
      </Typography>
      <div>
        <Typography
          sx={{ pt: 2, fontWeight: 'bold' }}
          variant="h3"
          align="right">
          {price}
        </Typography>
        <Typography sx={{ fontSize: '10px', pb: 2 }}>{priceHint}</Typography>
      </div>
    </div>
  );
};

export default PriceBox;
