import {
  DialogActions,
  FormControlLabel,
  IconButton,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { ReactElement, FC, ReactNode, SyntheticEvent } from 'react';
import { IOption } from '../Interface/Interface';
import { IOptionLabelFunc } from './DefaultComponent';
import { makeStyles } from 'tss-react/mui';
import { darkBlue, darkGray, disabledGray } from '../Constants/Colors';
import InfoIcon from '@mui/icons-material/Info';

import { Box } from '@mui/system';
import * as Showdown from 'showdown';
import CloseIcon from '@mui/icons-material/Close';
import { AbsoluteDialog } from './ContainerPositionProvider';

interface IOptionProps {
  option: IOption;
  // eslint-disable-next-line no-unused-vars
  onChange: (optionIdentifier: string) => void;
  control: ReactElement;
  optionLabel?: IOptionLabelFunc;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => {
  return {
    labelGroup: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden'
    },
    label: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      [theme.breakpoints.down('xs' && 580)]: {
        paddingLeft: '6px',
        paddingRight: '4px'
      },
      pointerEvents: 'auto'
    },
    dialog: {
      '& .MuiPaper-root': {
        maxWidth: 500,
        width: 500,
        '@media screen and (max-width: 500px)': {
          width: '80vw'
        }
      }
    },
    dialogContent: {
      padding: '15px !important',
      paddingTop: '0px !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'inherit !important'
    },
    infoButton: {
      display: 'flex',
      alignItems: 'center'
    },
    optionPrice: {
      minWidth: '75px ',
      textAlign: 'end'
    }
  };
});
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  openLinksInNewWindow: true
});

const DefaultOption: FC<IOptionProps> = ({
  option,
  onChange,
  control,
  optionLabel,
  disabled = false
}): ReactElement => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (event: SyntheticEvent) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { identifier, title, description } = option;

  const onFormControlChange = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    if (checked) {
      onChange(identifier);
    }
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const labelElement = (): ReactNode => {
    return (
      <>
        <div className={classes.labelGroup}>
          <Box className={classes.infoButton}>
            <Typography
              variant="h4"
              className={classes.label}
              sx={{ color: disabled ? disabledGray : darkGray }}>
              {optionLabel ? optionLabel(option) : title}
            </Typography>

            <Box>
              {description && (
                <>
                  <InfoIcon
                    sx={{ pt: 0.5, color: darkBlue, pointerEvents: 'auto' }}
                    onClick={handleClickOpen}
                  />

                  <AbsoluteDialog
                    open={open}
                    onClose={handleClose}
                    className={classes.dialog}>
                    <IconButton
                      disableRipple
                      sx={{ justifyContent: 'end' }}
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                    <DialogActions className={classes.dialogContent}>
                      <Typography sx={{ color: darkBlue }}>{title}</Typography>
                      <Typography
                        variant="h4"
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(description)
                        }}
                      />
                    </DialogActions>
                  </AbsoluteDialog>
                </>
              )}
            </Box>
          </Box>

          <Typography
            variant="h4"
            className={classes.optionPrice}
            sx={{
              color: disabled ? disabledGray : darkGray,
              pointerEvents: 'auto'
            }}>
            {option.priceFormatted}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <FormControlLabel
      disabled={disabled}
      disableTypography={true}
      sx={{ margin: '0px', pointerEvents: isMobile ? 'auto' : 'none' }}
      onChange={onFormControlChange}
      value={identifier}
      control={control}
      label={labelElement()}
    />
  );
};
export default DefaultOption;
