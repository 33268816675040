import {
  IAttribute,
  IComponent,
  IConfiguration,
  IItem,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import React, { FC, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { t } from 'react-i18nify';
import MultiSelectComponent from './MultiSelectComponent';
import { useAppSelector } from '../hooks';
import {
  findFirstSelectedOptionByComponentIdentifier,
  findSelectedOptionAmount,
  findSelectedOptionsByComponentIdentifier,
  getAllAttributeValues,
  getAttribute,
  getAttributeValue
} from '../Utils/FindInConfiguration';
import {
  ATTRIBUTE_KEY_ALLOWED_LICENSE_TYPES,
  ATTRIBUTE_KEY_CPU_CORES,
  ATTRIBUTE_KEY_HAS_ADDONS,
  ATTRIBUTE_KEY_LICENSE_TYPE,
  ATTRIBUTE_PRODUCT_TYPE,
  COMPONENT_CPU_IDENTIFIER,
  COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER,
  OPTION_NO_OPERATING_SYSTEM_IDENTIFIER,
  PRODUCT_TYPE_SERVER,
  PRODUCT_TYPE_WORKSTATION
} from '../Constants/Constants';
import { lightgray } from '../Constants/Colors';
import { intersection } from 'lodash';
import DefaultComponent from './DefaultComponent';

const OperatingSystemComponent: FC = (): ReactElement | null => {
  const useStyles = makeStyles()((theme) => {
    return {
      grid: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: theme.spacing(1),
        backgroundColor: lightgray
      }
    };
  });
  const { classes } = useStyles();

  const operatingSystems: IComponent | undefined = useAppSelector(
    (state) => state.configuration?.operatingSystemComponent.operatingSystem
  );
  const addons: IComponent | undefined = useAppSelector(
    (state) => state.configuration?.operatingSystemComponent.addons
  );
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const item: IItem | undefined = useAppSelector(
    (state) => state.configuration.configuration?.item
  );

  const selectedCpu = findFirstSelectedOptionByComponentIdentifier(
    COMPONENT_CPU_IDENTIFIER,
    selectedOptions,
    configuration
  );

  let selectedOperatingSystems: IOption[] = [];
  if (operatingSystems) {
    selectedOperatingSystems = findSelectedOptionsByComponentIdentifier(
      operatingSystems.identifier,
      selectedOptions,
      configuration
    );
  }
  // license types filter
  let cpuLicenseValues: string[] = [];
  if (selectedCpu) {
    const cpuLicense = getAttribute(
      selectedCpu,
      ATTRIBUTE_KEY_ALLOWED_LICENSE_TYPES
    );
    if (cpuLicense) {
      cpuLicenseValues = cpuLicense.values.map((attribute) => attribute.value);
    }
  }
  const filterByCpuLicense = (option: IOption) => {
    let osLicense: string[] = [];
    if (cpuLicenseValues.length > 0) {
      osLicense = getAllAttributeValues(
        option,
        ATTRIBUTE_KEY_LICENSE_TYPE
      ) as string[];
    }

    const productTypeValue = getAttributeValue(
      item,
      ATTRIBUTE_PRODUCT_TYPE
    ) as string;

    return (
      !(OPTION_NO_OPERATING_SYSTEM_IDENTIFIER === option.identifier) &&
      !intersection(cpuLicenseValues, osLicense).length &&
      (productTypeValue === PRODUCT_TYPE_WORKSTATION ||
        productTypeValue === PRODUCT_TYPE_SERVER)
    );
  };

  let hint;
  let hasAddons = false;
  for (const selectedOperatingSystem of selectedOperatingSystems) {
    const hasAddonsAttribute: IAttribute | undefined =
      selectedOperatingSystem.attributes.find(
        (attribute) => ATTRIBUTE_KEY_HAS_ADDONS === attribute.identifier
      );
    if (hasAddonsAttribute && hasAddonsAttribute.values[0]) {
      if (hasAddonsAttribute.attributedatatype === 'boolean') {
        hasAddons = hasAddonsAttribute.values[0].value;
      } else {
        hasAddons =
          hasAddonsAttribute.values[0].translations &&
          hasAddonsAttribute.values[0].translations.length > 0
            ? parseInt(hasAddonsAttribute.values[0].translated_value) === 1
            : parseInt(hasAddonsAttribute.values[0].value) === 1;
      }
    }
    // allow adding addons if any of the selected operating systems has the attribute
    if (hasAddons) {
      break;
    }
  }
  if (
    selectedOperatingSystems.length > 0 &&
    configuration &&
    operatingSystems?.identifier ===
      COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER
  ) {
    const selectedCpu = findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_CPU_IDENTIFIER,
      selectedOptions,
      configuration
    );
    let cores = 0;
    if (selectedCpu) {
      const selectedCpuAmount = findSelectedOptionAmount(
        selectedCpu,
        COMPONENT_CPU_IDENTIFIER,
        selectedOptions
      );
      const cpuCores: IAttribute | undefined = selectedCpu.attributes.find(
        (attribute) => ATTRIBUTE_KEY_CPU_CORES === attribute.identifier
      );
      if (cpuCores && cpuCores.values[0]) {
        const cpuCoresAmount =
          cpuCores.values[0].translations &&
          cpuCores.values[0].translations.length > 0
            ? parseInt(cpuCores.values[0].translated_value)
            : parseInt(cpuCores.values[0].value);
        cores = cpuCoresAmount * selectedCpuAmount;
      }
    }
    hint = t('osCoresHint', { cores });
  }

  if (operatingSystems) {
    return (
      <>
        <Grid className={classes.grid}>
          {operatingSystems?.identifier ===
          COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER ? (
            <MultiSelectComponent
              component={operatingSystems}
              hint={hint}
              checkOptionDisabled={filterByCpuLicense}
            />
          ) : (
            <DefaultComponent
              component={operatingSystems}
              checkOptionDisabled={filterByCpuLicense}
            />
          )}
          {addons && hasAddons && <MultiSelectComponent component={addons} />}
        </Grid>
      </>
    );
  }
  return null;
};

export default OperatingSystemComponent;
