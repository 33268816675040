import { combineReducers, configureStore } from '@reduxjs/toolkit';
import configurationReducer from './Reducers/ConfigurationSlice';

const reducer = combineReducers({
  configuration: configurationReducer
});

export const store = configureStore({
  reducer
});

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
