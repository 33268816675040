import { setLocale, setTranslations } from 'react-i18nify';
import { isProduction } from './configuration';

export async function initLanguage(lang) {
  // ensure that the filename always has an underscore and no dash
  const fileKey = lang.replace('-', '_');
  const langKey = lang.substring(0, 2);

  const results = isProduction()
    ? await Promise.all([
        loadLanguageJson(
          '/translations_cached/' + 'messages.' + fileKey + '.json',
          langKey
        )
      ])
    : await Promise.all([
        loadLanguageJson('/translations/' + fileKey + '.json', langKey),
        loadLanguageJson('/translations_custom/' + fileKey + '.json', langKey)
      ]);
  results.forEach((result) => setTranslations(result));
  setLocale(langKey);
}

function loadLanguageJson(filename, langKey) {
  return new Promise((resolve) => {
    let request = new XMLHttpRequest();

    request.open('GET', filename);
    request.addEventListener('load', () => {
      if (request.status >= 200 && request.status < 300) {
        try {
          let obj = {};
          obj[langKey] = JSON.parse(request.response);
          resolve(obj);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'error parsing translations json (' + filename + '): ' + e
          );
          resolve({});
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          'error loading translations (' + filename + '): ' + request.statusText
        );
        resolve({});
      }
    });
    request.send();
  });
}
