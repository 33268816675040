import React, { FC, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from '../hooks';
import { useAbsolutePositionStyle } from './ContainerPositionProvider';

const useStyles = makeStyles()(() => {
  return {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      zIndex: 2,
      backgroundColor: 'rgba(255,255,255,0.3)'
    },
    inner: {
      flex: 1,
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex'
    }
  };
});

const Progress: FC = (): ReactElement => {
  const { classes } = useStyles();

  const isLoading: boolean = useAppSelector(
    (state) => state.configuration.isLoading
  );

  const absolutePosition = useAbsolutePositionStyle();

  return (
    <Backdrop className={classes.container} open={isLoading}>
      <div className={classes.inner} style={absolutePosition}>
        <CircularProgress />
      </div>
    </Backdrop>
  );
};

export default Progress;
