import * as React from 'react';
import { Alert, Box, IconButton, Typography } from '@mui/material';
import { t } from 'react-i18nify';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { AbsoluteDialog } from './ContainerPositionProvider';
interface SnackbarProps {
  message?: string;
  onCloseMessage: () => void;
}

const useStyles = makeStyles()(() => {
  return {
    dialog: {
      minHeight: 'auto',
      minWidth: 'auto',
      border: '2px solid white !important',
      '& .MuiPaper-root': {
        maxWidth: 500,
        width: 500,
        display: 'block',
        '@media screen and (max-width: 500px)': {
          width: '80vw'
        }
      }
    },
    closeButton: {
      padding: '0 !important',
      width: '100%',
      justifyContent: 'end !important'
    }
  };
});

const Snackbar = ({ message, onCloseMessage }: SnackbarProps) => {
  const { classes } = useStyles();

  return (
    <AbsoluteDialog open={!!message} onClose={onCloseMessage}>
      <Box className={classes.dialog}>
        <Alert sx={{ p: 1, pt: 0 }} icon={false}>
          <IconButton
            disableRipple
            className={classes.closeButton}
            onClick={onCloseMessage}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ p: 1, pt: 0 }}>
            <Typography variant="h4" sx={{ pb: 1 }}>
              {t('notice')}:
            </Typography>
            <Typography variant="h4">{message && t(message)}</Typography>
          </Box>
        </Alert>
      </Box>
    </AbsoluteDialog>
  );
};

export default Snackbar;
