import { Grid, GridProps } from '@mui/material';
import React from 'react';
import { useAbsolutePosition } from './ContainerPositionProvider';

export const StickyGrid = ({
  bottomMargin = 0,
  ...props
}: GridProps & { bottomMargin?: number }) => {
  const absoluteBottom = useAbsolutePosition('bottom');
  const configurationInfoContainerRef = React.useRef<any>();

  React.useLayoutEffect(() => {
    const currentHeight = configurationInfoContainerRef.current?.clientHeight;
    if (currentHeight && absoluteBottom) {
      // prevent from going lower than the bottom of the outer container
      const containerBottom =
        configurationInfoContainerRef.current.parentElement.getBoundingClientRect()
          .bottom;
      const bottomMin = Math.min(containerBottom, absoluteBottom);
      configurationInfoContainerRef.current.style.top = `${
        bottomMin - currentHeight - bottomMargin
      }px`;
    }
  });
  return <Grid {...props} ref={configurationInfoContainerRef} />;
};
