import React, { ReactElement, FC } from 'react';
import {
  IAttribute,
  IComponent,
  IConfiguration,
  IOption,
  ISelectedOptions
} from '../Interface/Interface';
import { useAppSelector } from '../hooks';
import {
  findFirstSelectedOptionByComponentIdentifier,
  findSelectedOptionAmount
} from '../Utils/FindInConfiguration';
import {
  ATTRIBUTE_KEY_WATTAGE,
  COMPONENT_CPU_IDENTIFIER,
  COMPONENT_GRAPHICS_CARD_IDENTIFIER
} from '../Constants/Constants';
import DefaultComponent from './DefaultComponent';

const WATTAGE_BUFFER = 50;

interface IComponentProps {
  component: IComponent;
}

const PowerSuplyComponent: FC<IComponentProps> = ({
  component
}): ReactElement => {
  const selectedOptions: ISelectedOptions | undefined = useAppSelector(
    (state) => state.configuration.configuration?.selectedOptions
  );
  const configuration: IConfiguration | undefined = useAppSelector(
    (state) => state.configuration.configuration
  );
  const selectedCPU: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_CPU_IDENTIFIER,
      selectedOptions,
      configuration
    );

  const selectedGraphicsCard: IOption | undefined =
    configuration &&
    findFirstSelectedOptionByComponentIdentifier(
      COMPONENT_GRAPHICS_CARD_IDENTIFIER,
      selectedOptions,
      configuration
    );

  let cpuWattage = 0;
  if (selectedCPU) {
    const cpuWattageAttribute: IAttribute | undefined =
      selectedCPU.attributes.find(
        (attribute) => ATTRIBUTE_KEY_WATTAGE === attribute.identifier
      );
    if (cpuWattageAttribute && cpuWattageAttribute.values[0]) {
      cpuWattage =
        cpuWattageAttribute.values[0].translations &&
        cpuWattageAttribute.values[0].translations.length > 0
          ? parseInt(cpuWattageAttribute.values[0].translated_value)
          : parseInt(cpuWattageAttribute.values[0].value);
    }
    const amount = findSelectedOptionAmount(
      selectedCPU,
      COMPONENT_CPU_IDENTIFIER,
      selectedOptions
    );
    cpuWattage = cpuWattage * amount;
  }

  let graphicCardWattage = 0;
  if (selectedGraphicsCard) {
    const graphicCardWattageAttribute: IAttribute | undefined =
      selectedGraphicsCard.attributes.find(
        (attribute) => ATTRIBUTE_KEY_WATTAGE === attribute.identifier
      );
    if (graphicCardWattageAttribute && graphicCardWattageAttribute.values[0]) {
      graphicCardWattage =
        graphicCardWattageAttribute.values[0].translations &&
        graphicCardWattageAttribute.values[0].translations.length > 0
          ? parseInt(graphicCardWattageAttribute.values[0].translated_value)
          : parseInt(graphicCardWattageAttribute.values[0].value);
    }
  }
  const isOptionDisabled = (option: IOption) => {
    let powerAdapterWattageOption = WATTAGE_BUFFER;
    const powerAdapterWattageOptionAttribute: IAttribute | undefined =
      option.attributes.find(
        (attribute) => ATTRIBUTE_KEY_WATTAGE === attribute.identifier
      );
    if (
      powerAdapterWattageOptionAttribute &&
      powerAdapterWattageOptionAttribute.values[0]
    ) {
      powerAdapterWattageOption =
        powerAdapterWattageOptionAttribute.values[0].translations &&
        powerAdapterWattageOptionAttribute.values[0].translations.length > 0
          ? parseInt(
              powerAdapterWattageOptionAttribute.values[0].translated_value
            )
          : parseInt(powerAdapterWattageOptionAttribute.values[0].value);
    }
    let isWattageSufficient;
    if (component.options.length === 1) {
      isWattageSufficient = true;
    } else {
      isWattageSufficient =
        powerAdapterWattageOption >=
        cpuWattage + graphicCardWattage + WATTAGE_BUFFER;
    }

    return !isWattageSufficient;
  };

  return (
    <DefaultComponent
      component={component}
      checkOptionDisabled={isOptionDisabled}
      disableAmountSelection={true}
    />
  );
};

export default PowerSuplyComponent;
