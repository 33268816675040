import { ATTRIBUTE_KEY_COMPONENT_FILTERS } from '../Constants/Constants';
import { IComponent, IFilter, IOption } from '../Interface/Interface';
import { getAttributeValue } from './FindInConfiguration';
import { getParsedJSON } from './getParsedValues';

export const generateFilterData = (
  component: IComponent,
  availableOptions?: IOption[] | undefined
) => {
  const attrValues: string = getAttributeValue(
    component,
    ATTRIBUTE_KEY_COMPONENT_FILTERS
  );
  const filterArray: string[] = attrValues.split(',');
  const filters: IFilter[] = [];
  const componentOptions = availableOptions || component.options;
  const attrArr: any[] = [];

  componentOptions.forEach((opt) => {
    opt.attributes.forEach((attr) => {
      const index = attrArr.findIndex(
        (obj) => obj.identifier === attr.identifier
      );
      if (index === -1) {
        attrArr.push(attr);
      }
    });
  });

  attrArr.forEach((attr: IFilter) => {
    const filterValues = new Set();
    if (filterArray.includes(attr.identifier)) {
      const filter = { ...attr };

      componentOptions.forEach((option) => {
        option.attributes.forEach((optionAttribute) => {
          if (
            optionAttribute.identifier === attr.identifier &&
            !filterValues.has(JSON.stringify(optionAttribute.values[0]))
          ) {
            filterValues.add(JSON.stringify(optionAttribute.values[0]));
          }
        });
      });

      // @ts-ignore
      const items = [...filterValues];
      const parsedFilters = getParsedJSON(items);
      parsedFilters.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );

      filter.filterValues = parsedFilters as any[];

      if (filter.filterValues.length) {
        filters.push(filter);
      }
    }
  });

  return filters;
};

export const filterOptions = (targetArray: any[], filters: any) => {
  if (!filters) return targetArray;

  const filterKeys = Object.keys(filters);

  return targetArray.filter(function (obj: any) {
    // @ts-ignore
    return filterKeys.every(function (key) {
      if (!filters[key].length || !obj.attributes.length) {
        return true;
      }

      const objAttributes = obj.attributes.find(
        (a: any) => a.identifier === key
      );

      if (!objAttributes) return false;

      const attrVal = objAttributes.values[0];

      // @ts-ignore
      return filters[key].some(function (filter) {
        return filter.id === attrVal.id;
      });
    });
  });
};
