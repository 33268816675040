import {
  IAttribute,
  IComponent,
  IOption,
  IRaidLevelOption
} from '../Interface/Interface';
import {
  ATTRIBUTE_KEY_DRIVE_TYPE,
  ATTRIBUTE_KEY_FORM_FACTOR,
  ATTRIBUTE_KEY_RAID_LEVEL_MIN_DISK,
  ATTRIBUTE_RAID_LEVEL_ALLOWED,
  ATTRIBUTE_VALUE_HDD,
  ATTRIBUTE_VALUE_M2,
  ATTRIBUTE_VALUE_NVME,
  ATTRIBUTE_VALUE_SSD,
  COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER,
  COMPONENT_OPERATING_SYSTEM_WORKSTATION_IDENTIFIER
} from '../Constants/Constants';

export const getHDDs = (component: IComponent): IOption[] => {
  return component.options.filter((option) =>
    option.attributes.find(
      (attribute) =>
        ATTRIBUTE_KEY_DRIVE_TYPE === attribute.identifier &&
        attribute.values.length > 0 &&
        attribute.values[0].value === ATTRIBUTE_VALUE_HDD
    )
  );
};

export const getSSDs = (component: IComponent): IOption[] => {
  return component.options.filter((option) =>
    option.attributes.find(
      (attribute) =>
        ATTRIBUTE_KEY_DRIVE_TYPE === attribute.identifier &&
        attribute.values.length > 0 &&
        attribute.values[0].value === ATTRIBUTE_VALUE_SSD &&
        !option.attributes.find(
          (attribute) =>
            ATTRIBUTE_KEY_FORM_FACTOR === attribute.identifier &&
            attribute.values.length > 0 &&
            attribute.values[0].value === ATTRIBUTE_VALUE_M2
        )
    )
  );
};

export const getM2s = (component: IComponent): IOption[] => {
  return component.options.filter((option) =>
    option.attributes.find(
      (attribute) =>
        ATTRIBUTE_KEY_FORM_FACTOR === attribute.identifier &&
        attribute.values.length > 0 &&
        attribute.values[0].value === ATTRIBUTE_VALUE_M2
    )
  );
};

export const getNvmes = (component: IComponent): IOption[] => {
  return component.options.filter(
    (option) =>
      option.attributes.find(
        (attribute) =>
          ATTRIBUTE_KEY_DRIVE_TYPE === attribute.identifier &&
          attribute.values.length > 0 &&
          attribute.values[0].value === ATTRIBUTE_VALUE_NVME
      ) &&
      !option.attributes.find(
        (attribute) =>
          ATTRIBUTE_KEY_FORM_FACTOR === attribute.identifier &&
          attribute.values.length > 0 &&
          attribute.values[0].value === ATTRIBUTE_VALUE_M2
      )
  );
};

export const getRaidLevelOptions = (
  component: IComponent
): IRaidLevelOption[] => {
  return component.options.map((option) => {
    const raidLevelOption: IRaidLevelOption = {
      ...option,
      minimumDrivesRequired: 0
    };
    const driveAmountAttribute = option.attributes.find(
      (attribute) => ATTRIBUTE_KEY_RAID_LEVEL_MIN_DISK === attribute.identifier
    );
    if (driveAmountAttribute) {
      raidLevelOption.minimumDrivesRequired =
        driveAmountAttribute.values[0].translations &&
        driveAmountAttribute.values[0].translations.length > 0
          ? parseInt(driveAmountAttribute.values[0].translated_value)
          : parseInt(driveAmountAttribute.values[0].value);
    }
    return raidLevelOption;
  });
};

export const getAllowedRaidLevels = (component: IComponent) => {
  const allowedRaidLevels: any = {};
  component.options.forEach((option: IOption) => {
    const raidLevelAllowedAttribute: IAttribute | undefined =
      option.attributes.find(
        (attribute) => ATTRIBUTE_RAID_LEVEL_ALLOWED === attribute.identifier
      );

    if (raidLevelAllowedAttribute) {
      allowedRaidLevels[option.identifier] = [];
      raidLevelAllowedAttribute.values.forEach((attributeValue) =>
        allowedRaidLevels[option.identifier].push(attributeValue.value)
      );
    }
  });

  return allowedRaidLevels;
};

export const sortOptionsByPrice = (
  component: IComponent,
  priceField: string
) => {
  component.options.sort((a, b) =>
    a.identifier.startsWith('no_')
      ? -1
      : // @ts-ignore
      a[priceField] > b[priceField]
      ? 1
      : // @ts-ignore
      a[priceField] < b[priceField]
      ? -1
      : 0
  );
};

export const getPriceField = (): string => {
  const query = new URLSearchParams(location.search);
  if (
    query.has('_display_net_prices') &&
    query.get('_display_net_prices') === '1'
  ) {
    return 'netPrice';
  }

  return 'price';
};

export const isOperatingSystem = (component: IComponent): boolean =>
  component.identifier === COMPONENT_OPERATING_SYSTEM_SERVER_IDENTIFIER ||
  component.identifier === COMPONENT_OPERATING_SYSTEM_WORKSTATION_IDENTIFIER;
