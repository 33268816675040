import { ThemeProvider } from '@mui/material/styles';
import theme from './CustomTheme';
// @ts-ignore
import React from 'react';
import ConfiguratorScreen from './Screens/ConfiguratorScreen';
import { init } from './configuration';
import { store } from './store';
import { Provider } from 'react-redux';
import { ContainerPositionProvider } from './Components/ContainerPositionProvider';

function App() {
  init();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ContainerPositionProvider>
          <ConfiguratorScreen />
        </ContainerPositionProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
