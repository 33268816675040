import { Box, Typography } from '@mui/material';
import React, { ReactElement, FC, useRef } from 'react';
import { IItem } from '../Interface/Interface';
import { makeStyles } from 'tss-react/mui';
import * as Showdown from 'showdown';
import { getImageUrl } from '../Utils/getImageUrl';
import SaveConfiguration from './SaveConfiguration';
import LoadConfiguration from './LoadConfiguration';

interface props {
  item: IItem;
  // eslint-disable-next-line no-unused-vars
  onImageLoaded?: (width: number, height: number) => void;
}

const useStyles = makeStyles()((theme) => {
  return {
    MuiDialogBorder: {
      borderTop: '7px solid black !important'
    },
    image: {
      marginTop: 9,
      marginBottom: 16,
      maxWidth: '100%',
      maxHeight: '100%'
    },
    itemTitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px !important'
      }
    }
  };
});
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  openLinksInNewWindow: true
});

const Item: FC<props> = ({ item, onImageLoaded }): ReactElement => {
  const { classes } = useStyles();
  const imgRef = useRef();
  const onImageLoad = () => {
    if (onImageLoaded) {
      /*// @ts-ignore */
      const height = imgRef?.current?.height;
      /*// @ts-ignore */
      const width = imgRef?.current?.width;
      onImageLoaded(width, height);
    }
  };
  return (
    <>
      <Typography variant="h1" className={classes.itemTitle}>
        {item.title ? item.title : ''}
      </Typography>
      <Box>
        <SaveConfiguration />
        <LoadConfiguration />
      </Box>

      {item.detailImage ? (
        <img
          /*// @ts-ignore */
          ref={imgRef}
          src={getImageUrl(item.detailImage)}
          alt=""
          className={classes.image}
          onLoad={onImageLoad}
        />
      ) : null}
      <Typography
        variant="h4"
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(item.description)
        }}
      />
    </>
  );
};

export default Item;
